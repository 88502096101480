import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../selectors";
import { setSelectedMacro, deleteMacro, getMacroDetails } from "../../actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  likeDownloadMacro,
  goToHome,
  setSearchQuery,
  searchResults
} from "../../actions/commonActions";
import { sendEventStats } from "../../actions/eventActions";
import { FormattedMessage } from "react-intl";
import DetailItem from "../common/DetailItem";
import DisqusBox from "../common/DisqusBox/DisqusBox";
import CommonButton from "../common/CommonButton/CommonButton";
import DownloadMacroModal from "../common/DownloadMacroModal/DownloadMacroModal";
import Loader from "../common/Loader/Loader";
import GoogleSignIn from "../common/GoogleSignIn/GoogleSignIn";
import { getMacros, setEndOfMacros } from "../../actions";
import CommonSection from "../common/CommonSection/CommonSection";

const MacroDetails = ({ showDefaultSearch, disqusData, showSearchResults, setUserUploads, setUser, userUploads, gameSearchResults }) => {
  const { macroId } = useParams();
  const email = useSelector(state => state.user.user_email);
  let macros = useSelector(state => selectors.macrosSelector(state));
  const selectedMacro = useSelector(state => state.apps.selectedMacro);
  const filteredMacros = useSelector(state => state.apps.filteredMacros);
  const dispatch = useDispatch();
  const [downloadModal, showDownloadModal] = useState(false);
  const [isLikeClicked, likeClicked] = useState(false);
  const [googleSignIn, showSignInModal] = useState(false);
  const [ots, showOts] = useState(false);
  const sortBy = useSelector(state => state.apps.sort_by);
  const macroDetailSuccess = useSelector(state => state.apps.macroDetailSuccess);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric"
  };
  const searchedString = useSelector(state => state.apps.searchedString);
  const loader = useSelector(state => state.apps.loader);
  const history = useHistory();
  const macrosString = (gameSearchResults || userUploads || searchedString) ? "filteredMacros" : "macroList";
  const filterMacroslist = (event, query, pkg) => {
    if(event) event.stopPropagation();
    dispatch(setSearchQuery(query));
    dispatch(searchResults(query));
    dispatch(setEndOfMacros(true));
    showSearchResults(query);
    history.push({
      pathname: `/macroList/${query}`,
      search: `?searchTerm=${query}${pkg ? `&app_pkg=${pkg}` : ""}`
    });
  };

  const openModal = (event, args) => {
    if (event) {
      event.stopPropagation();
    }
    if (email) {
      if (window.GmApi.isAvailable()) showDownloadModal(true);
      else {
        dispatch(likeDownloadMacro(args.id, "download", email, macrosString));
        document.getElementById("downloadMacro").click();
      }
    } else {
      if (!window.GmApi.isAvailable()) {
        showSignInModal(true);
      }
      else {
        showOts(true);
        showSignInModal(true);
      }
    }
  };

  const likeMacro = (id, isLiked) => {
    if (email) {
      likeClicked(true);
      if (isLikeClicked) {
        return;
      }
      dispatch(likeDownloadMacro(id, "like", email, macrosString)).then(() => likeClicked(false));
      const macroLike = isLiked ? 'macroDislike' : 'macroLike';
      sendEventStats(selectedMacro, macroLike , email);
    } else {
      if (!window.GmApi.isAvailable()) {
        showSignInModal(true);
      } else {
        showOts(true);
        showSignInModal(true);
      }
    }
  };

  const showUserUploads = (event, user) => {
    if (event) event.stopPropagation();
    setUser(user);
    dispatch(getMacros(user, null, null, sortBy, null, true, true, user, true));
    dispatch(setEndOfMacros(false));
    setUserUploads(true);
    history.push({
      pathname: "/",
      search: `?email=${user}`
    });
  };

  const loadDisqusData = () => {
    if (!document.getElementById("dsq-count-scr")) {
      const disqus_shortname = "bluestacks-community";
      const dsqCount = document.createElement("script");
      dsqCount.type = "text/javascript";
      dsqCount.async = true;
      dsqCount.src = "https://" + disqus_shortname + ".disqus.com/count.js";
      document.getElementsByTagName("body")[0].appendChild(dsqCount);
      dsqCount.id = "dsq-count-scr";
      dsqCount.onload = function() {
        window.DISQUSWIDGETS.getCount({ reset: true });
      };
    } else {
      if (window.DISQUSWIDGETS) {
        window.DISQUSWIDGETS.getCount({ reset: true });
      }
    }
  }

  useEffect(() => {
    showDefaultSearch(false);
    
    if (macroId && macros) {
      let selectedMacros = searchedString ? filteredMacros : macros;
      let macro = selectedMacros.filter(macro => macro.content_id === macroId);
      if (macro && macro.length) {
        dispatch(setSelectedMacro(macro[0]));
        loadDisqusData();
      }
      else {
        loadDisqusData();
        dispatch(getMacroDetails(email, macroId));
      }
    }

    return function cleanup() {
      dispatch(setSelectedMacro(undefined));
      showDefaultSearch(true);
    };
  }, [macroId, macros, disqusData,filteredMacros]);
  if (loader) return <Loader />;
  if (!macroDetailSuccess) return null;
  if (macroDetailSuccess && !selectedMacro){
    return (
      <CommonSection
        heading="scriptDeleted"
        subHeading="scriptLooking"
        img={`${process.env.PUBLIC_URL}/images/macro_icon.svg`}
        btnText="goToCommumity"
        onClick={()=> goToHome()}
      />
    );
  }
  window.scrollTo(0, 0);
  return (
    <>    
      <div className="macroList">
        <div className="macroDetails">
          <div className="flex spaceBetween macroDetailsContainer">
            <div className="flex macroInfo vrtlCenter">
              <img
                alt={selectedMacro.app_pkg}
                src={`https://cloud.bluestacks.com/app/icon?pkg=${selectedMacro.app_pkg}`}
              />
              <div className="flex column hideOverflow description">
                <span className="font24 textColorHeading">
                  {selectedMacro.content_name}
                </span>
                <p className="flex macroName spaceBetween font12 lightGreyColor">
                  <span className="gameName">
                    <span className="margin-right-5">
                      <FormattedMessage id="game" />
                    </span>
                    <span
                      className="linkColor pointer"
                      onClick={(event) => filterMacroslist(event, selectedMacro.game_name, selectedMacro.app_pkg)}
                      dangerouslySetInnerHTML={{__html: selectedMacro.game_name}}
                    >
                    </span>
                  </span>
                  <span className="authorName">
                    <span className="margin-right-5">
                      <FormattedMessage id="author" />
                    </span>
                    <span
                      className="linkColor pointer"
                      onClick={(event) =>
                        showUserUploads(event, selectedMacro.uploader_email)
                      }
                    >
                      {selectedMacro.uploader_nickname}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div className="macroDownload flex column spaceBetween">
              <div className="flex vrtlCenter spaceBetween font14 textColorHeading">
                <DetailItem
                  className="downloads pointer"
                  value={
                    selectedMacro.download_count
                      ? selectedMacro.download_count
                      : 0
                  }
                  onClick={(event) => openModal(event, {id: selectedMacro.content_id})}
                />
                <DetailItem
                  className={`likes ${isLikeClicked ? "" : selectedMacro.is_liked ? "liked pointer" : "pointer"}`}
                  value={
                    selectedMacro.likes_count ? selectedMacro.likes_count : 0
                  }
                  isLiked={selectedMacro.is_liked}
                  id={selectedMacro.content_id}
                  onClick={() =>
                    likeMacro(selectedMacro.content_id, selectedMacro.is_liked)
                  }
                />
                <CommonButton
                  className={`downloadButton primaryBtn noWrapText`}
                  text="get"
                  params={{id: selectedMacro.content_id}}
                  onClick={openModal}
                />
              </div>
              <p className="flex hrtlCenter font12 timestamp">
                <span className="margin-right-5 lightGreyColor">
                  <FormattedMessage id="lastUpdated" />
                </span>
                <span className="mediumGreyColor">
                  {new Intl.DateTimeFormat("indian", options).format(
                    new Date(selectedMacro.modified_at)
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="font14 textColorSubHeading margin-bottom-10 macroDesc">
            {selectedMacro.content_description}
          </div>
          <DisqusBox id={macroId} disqusData={disqusData} />
        </div>
        {downloadModal && (
          <DownloadMacroModal
            macroName={selectedMacro.content_name}
            download_link={selectedMacro.download_link}
            macroId={macroId}
            closeModal={() => showDownloadModal(false)}
            nickName={selectedMacro.uploader_nickname}
            uploaderEmail={selectedMacro.uploader_email}
            headingTextId="importMacro"
            descriptionTextId="areYouSure"
            macro={selectedMacro}
          />
        )}
        <a
          id="downloadMacro"
          href={selectedMacro.download_link}
          download={selectedMacro.content_name}
        >
          Download
        </a>
        {googleSignIn && <GoogleSignIn showSignInModal={showSignInModal} macroDetails={true} ots={ots} />}
      </div>
    </>
  );
};

export default MacroDetails;
