import consts from "../consts";
import { macroDataTransformer, userDataTransformer, filteredMacroDataTransformer } from "../transformers";
import axios from "axios";
import DOMPurify from 'dompurify';

const urlParams = new URLSearchParams(window.location.search);
const mod = urlParams.get("module") === 'dev' ? "https://ayush-macro2-dot-bs3-appcenter-engg.uc.r.appspot.com" : null;
const host = window.GmApi.gmDevUrl() || mod || "https://cloud.bluestacks.com";
const locale = window.GmApi.gmGetLocale() || 'en-US';

export const getMacros = (
  email, 
  query, 
  appPkg, 
  sortBy, 
  cursor, 
  loader, 
  resetData,
  uploader_email,
  resetFilteredData
  ) => {
  const content = "macro";
  const limit = 20;
  const url = `${host}/communitycontent/macro?user_email=${DOMPurify.sanitize(email)}&content_type=${DOMPurify.sanitize(content)}&locale=${DOMPurify.sanitize(locale)}${DOMPurify.sanitize(query) ? `&search_query=${DOMPurify.sanitize(query)}`: ''}${DOMPurify.sanitize(appPkg) ? `&app_pkg=${DOMPurify.sanitize(appPkg)}`: ''}`;
  return async dispatch => {
    if(loader) {
      dispatch({
        type: consts.SHOW_LOADER
      });
    } else {
      dispatch({
        type: consts.HIDE_LOADER
      });
    }
    try {
      const response = await axios({
        method: "get",
        params: {
          cursor: cursor ? cursor : "",
          sort_by: sortBy,
          uploader_email: uploader_email ? uploader_email : "",
          limit: limit
        },
        url: url
      });
      const resultLen = response.data && response.data.result.length;
      if(query || resultLen < limit) {
        dispatch({
          type: consts.END_OF_MACROS,
          payload: true
        });
      }
      if(query || uploader_email)
      {
        if(resetFilteredData || query) {
          dispatch({
            type: consts.SHOW_LOADER
          });
          dispatch({
            type: consts.RESET_FILTERED_MACROS
          });
        }
      return dispatch({
        type: consts.MACROS_SEARCH_DATA_SUCCESS,
        payload: filteredMacroDataTransformer(response)
      }),
      dispatch({
        type: consts.SEARCHED_STRING,
        payload: true
      });
    }
    else{
      if(resetData) {
        dispatch({
          type: consts.RESET_MACROS
        });
      }
      return dispatch({
        type: consts.MACROS_DATA_SUCCESS,
        payload: macroDataTransformer(response)
      });
    }
    } catch (error) {
      return dispatch({
        type: consts.HIDE_LOADER
      });
    }
  };
};
export const getSearchRecommendations = () => {
  const url = `${host}/communitycontent/get_search_recommendations?locale=${locale}`;
  return async dispatch => {
    try {
      const response = await axios({
        method: "get",
        url: url
      });
      return dispatch({
        type: consts.SEARCH_RECOMMENDATION_SUCCESS,
        payload: response.data
      });
    }
    catch {

    }
  }
};
export const getAllGames = () => {
  const url = `${host}/communitycontent/get_all_macro_game_count?locale=${locale}`;
  return async dispatch => {
    try {
      const response = await axios({
        method: "get",
        url: url
      });
      return dispatch({
        type: consts.ALL_GAMES_SUCCESS,
        payload: response.data
      });
    }
    catch {

    }
  }
}
export const setSearchedString = isSearched => {
  return {
    type: consts.SEARCHED_STRING,
    payload: isSearched
  };
};

export const setSelectedMacro = macro => {
  return {
    type: consts.SET_SELECTED_MACRO,
    payload: macro
  };
};

export const showFilteredMacros = game => {
  return {
    type: consts.SHOW_FILTERED_MACROS,
    payload: game
  };
};

export const deleteMacro = id => {
  const url = `${host}/communitycontent/delete_content?content_id=${id}`;
  return async dispatch => {
    dispatch({
      type: consts.SHOW_LOADER
    });
    try {
      await axios({
        method: "post",
        url: url
      });
      return dispatch({
        type: consts.MACROS_DELETE_SUCCESS,
        payload: id
      });
    } catch (error) {
      return error;
    }
  };
};

export const uploadMacroFile = (
  email,
  title,
  description,
  tags,
  selectedMacro,
  content_id,
  packageName,
  game,
  nickName,
  subscribed
) => {
  const url = `${host}/communitycontent/macro`;
  let bodyFormData = new FormData();
  bodyFormData.set("uploader_email", email);
  bodyFormData.set("content_name", title);
  bodyFormData.set("content_description", description);
  bodyFormData.set("content_tags", tags);
  bodyFormData.set("subscribedToEmails", subscribed);
  bodyFormData.append("macro_file", selectedMacro);
  bodyFormData.append("game_name", game);
  bodyFormData.append("app_pkg", packageName);
  bodyFormData.append("uploader_nickname", nickName);
  if (content_id) {
    bodyFormData.set("content_id", content_id);
    bodyFormData.set("mode", "edit");
  }
  return async dispatch => {
    dispatch({
      type: consts.SHOW_LOADER
    });
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: bodyFormData
      });
      if (content_id) {
        return dispatch({
          type: consts.MACROS_EDIT_SUCCESS,
          payload: response.data
        });
      }
      return dispatch({
        type: consts.MACROS_UPLOAD_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      return dispatch({
        type: consts.HIDE_LOADER,
        errorMsg: true
      });
    }
  };
};

export const getSSOCredentials = (nickname, userId, avatar, email) => {
  const url = `${host}/discuss_signin?token=${userId}&nickname=${nickname}&email=${email}&avatar=${avatar}`;
  return async dispatch => {
    try {
      const response = await axios({
        method: "get",
        url: url
      });
      return dispatch({
        type: consts.SSO_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      return error;
    }
  };
};

export const filterUlpoadsByUser = user => {
  return {
    type: consts.SHOW_USER_FILTERED_MACROS,
    payload: user
  };
}
export const getGames = (gamesInstalledString) =>{
  const url = `${host}/communitycontent/get_game_name`;
  let bodyFormData = new FormData();
  bodyFormData.set("installed_games", gamesInstalledString);
  bodyFormData.set("locale", locale);
  return async dispatch => {
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: bodyFormData
      });
      return dispatch({
        type: consts.GET_GAMES_SUCCESS,
        payload: response.data
      })
    }
    catch (error) {
      return error;
    }
  }
}

export const setSortParameter = param => {
  return {
    type: consts.SORT_STATUS,
    payload: param
  }
}

export const setSortParameterAllGames = param => {
  return {
    type: consts.SORT_STATUS_ALL_GAMES,
    payload: param
  }
};

export const setSortedMacros = (macros, type) => {
  return {
    type: consts.SET_SORTED_MACROS,
    payload: {macros, type}
  }
}

export const setEndOfMacros = flag => {
  return {
    type: consts.END_OF_MACROS,
    payload: flag
  }
}

export const showLoader = () => {
  return {
    type: consts.SHOW_LOADER
  }
}

export const hideLoader = () => {
  return {
    type: consts.HIDE_LOADER
  }
}

export const resetFilteredMacrosData = () => {
  return {
    type: consts.RESET_FILTERED_MACROS
  }
}
export const resetMacrosData = () => {
  return {
    type: consts.RESET_MACROS
  }
};

export const getMacroDetails = (email, macroId) => {
  const url = `${host}/communitycontent/macro?content_type=macro&locale=${locale}&user_email=${email}&macro_id=${macroId}`;
  return async dispatch => {
    dispatch({
      type: consts.MACRO_DETAILS_FETCH
    });
    try {
      const response = await axios({
        method: "get",
        url: url
      });
      return dispatch({
        type: consts.MACRO_DETAILS_SUCCESS,
        payload: response.data.result
      });
    } catch (error) {
      return error;
    }
  };
}
