import React, { useState, useEffect,useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import SearchBox from "../SearchBox/SearchBox";
import {
  goToHome,
  setSearchQuery,
  searchResults
} from "../../../actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory,Link } from "react-router-dom";
import GoogleSignIn from "../GoogleSignIn/GoogleSignIn";
import DOMPurify from 'dompurify';
import {
  getMacros,
  getSSOCredentials,
  setSortParameter,
  setSortParameterAllGames,
  showLoader,
  hideLoader,
  setEndOfMacros,
  setSearchedString
} from "../../../actions";
import { macrosSelector } from "../../../selectors";
import ReactGA from 'react-ga';
import { MenuItem,Select } from "@material-ui/core";
import { sendEventStats } from "../../../actions/eventActions";
import DownloadMacroModal from "../../common/DownloadMacroModal/DownloadMacroModal";
import { deleteMacro } from "../../../actions";

const Header = ({
  resetSearch,
  searchResultList,
  userUploads,
  searchCount,
  showHeader,
  reloadMacros,
  showSearchResults,
  setUserUploads,
  showSortOnAllGames,
  intl,
  user,
  setUser,
  setSearchResults
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [googleSignIn, showSignInModal] = useState(false);
  const [ots, showOts] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const email = useSelector(state => state.user.user_email);
  const category = useSelector(state => state.apps.category);
  const gameName  = useSelector(state => state.apps.gameName);
  const [isOwner, setOwner] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [top, resetOffsetTop] = useState(0);
  const sortBy = useSelector(state => state.apps.sort_by);
  const loader = useSelector(state => state.apps.loader); 
  const macros_count = useSelector(state => state.apps.total_macros);
  const searchQuery = useSelector(state => state.apps.searchQuery);
  const filterMacroslist = (query, pkg, type) => {
    query = DOMPurify.sanitize(query);
    pkg = DOMPurify.sanitize(pkg);
    if (!type) {
      dispatch(getMacros(email, query, pkg, sortBy, null, true, true));
      dispatch(setSearchQuery(query));
      dispatch(searchResults(query));
      showSearchResults();
    }
    if (type === "searchbox") {
      history.push({
        pathname: `/macroList/${query}`,
        search: `?searchTerm=${query}${appPkg && pkg ? `&app_pkg=${pkg}` : "" }`
      });
      ReactGA.event({
        category: category,
        action: "Search Macro Form Submit",
        label: query
      });
    }
    if (type === "recommendation") {
      history.push({
        pathname: `/macroList/${query}`,
        search: `?searchTerm=${query}${pkg ? `&app_pkg=${pkg}` : "" }`
      });
      ReactGA.event({
        category: category,
        action: "Search Macro Link Click",
        label: query
      });
    }
  };
  const guid = window.GmApi.gmGetGuid();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const searchTerm = urlParams.get("searchTerm");
  const appPkg = urlParams.get("app_pkg");
  let macroList = useSelector(state => macrosSelector(state));
  const selectedMacro = useSelector(state => state.apps.selectedMacro);

  let macros;
  const filteredMacros = useSelector(state => state.apps.filteredMacros);
  macros = gameName || userUploads ? filteredMacros : macroList;
  const headerContainer = useRef(null);
  const subHeaderContainer = useRef(null);
  const selectedSortingValue = useSelector(state => state.apps.sort_by);
  const selectedSortingValueAllGames = useSelector(state => state.apps.sort_by_all_games);
  let offsetTop;
  const [recommendedOptions, setRecommendedOption] = useState(false);

  const updateOffSetTop = () => {
    offsetTop =
      headerContainer &&
      headerContainer.current &&
      headerContainer.current.getBoundingClientRect().height;
      resetOffsetTop(offsetTop);
    }

  useEffect(() => {
    updateOffSetTop();
    window.addEventListener("resize", updateOffSetTop);
    return () => window.removeEventListener("resize", updateOffSetTop);
  }, [])

  useEffect(() => {
    if (selectedMacro) {
      setOwner(email === selectedMacro.uploader_email);
    }
  }, [selectedMacro]);
  
  const MenuProps = {
    PaperProps: {
      style: {
        overflowY: "hidden",
        boxShadow: "0 0 0",
        border: "1px solid #d2d4e0"
      }
    },

    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    }
  };

  let sortingOptions = [
    {
      key: "modified_at",
      value: <FormattedMessage id="modified_at" />
    },
    {
      key: "comment_count",
      value: <FormattedMessage id="comment_count" />
    },
    {
      key: "likes_count",
      value: <FormattedMessage id="likes_count" />
    },
    {
      key: "download_count",
      value: <FormattedMessage id="download_count" />
    }
  ];

  let sortingOptionsForAllGames = [
  {
      key: "macro_count",
      value: <FormattedMessage id="macro_count" />
  },
  {
      key: "modified_at",
      value: <FormattedMessage id="modified_at" />
  },
  {
      key: "alphabetical",
      value: <FormattedMessage id="alphabetical" />
    }
  ];

  let recommendedSortingOptions = [
    {
    key: "recommended",
      value: <FormattedMessage id="recommended" />
    },
    {
      key: "modified_at",
      value: <FormattedMessage id="modified_at" />
    },
    {
      key: "comment_count",
      value: <FormattedMessage id="comment_count" />
    },
    {
      key: "likes_count",
      value: <FormattedMessage id="likes_count" />
    },
    {
      key: "download_count",
      value: <FormattedMessage id="download_count" />
    }
  ];

  const deleteMacroById = () => {
    dispatch(deleteMacro(selectedMacro.content_id)).then(() => {
    showDeleteModal(false);
    history.goBack();
    });
  };

  function currentYPosition() {
    if (window.pageYOffset) return window.pageYOffset;
  }

  function bodyYPosition() {
      var elm = document.getElementsByTagName("body");
      var y = elm[0].offsetTop;
      var node = elm;
      while (node.offsetParent && node.offsetParent !== document.body) {
          node = node.offsetParent;
          y += node.offsetTop;
  }
    return y;
  }

  function smoothScroll() {
      var startY = currentYPosition();
      var stopY = bodyYPosition();
      var distance = stopY > startY ? stopY - startY : startY - stopY;
      if (distance < 100) {
      window.scrollTo(0, stopY);
      return;
      }
      var speed = Math.round(distance / 100);
      if (speed >= 20) speed = 20;
      var step = Math.round(distance / 25);
      var leapY = stopY > startY ? startY + step : startY - step;
      var timer = 0;
      if (stopY > startY) {
      for (var i = startY; i < stopY; i += step) {
        setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
        leapY += step;
        if (leapY > stopY) leapY = stopY;
        timer++;
      }
      return;
      }
    for (var i = startY; i > stopY; i -= step) {
      setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
      leapY -= step;
      if (leapY < stopY) leapY = stopY;
      timer++;
  }
  }

  const sortMenu = () => {
    const sortParam = showSortOnAllGames
      ? selectedSortingValueAllGames
      : selectedSortingValue;
    const setSortParam = val =>
      showSortOnAllGames
        ? dispatch(setSortParameterAllGames(val))
        : dispatch(setSortParameter(val));
    const listOptions = recommendedOptions
      ? recommendedSortingOptions
      : showSortOnAllGames
      ? sortingOptionsForAllGames
      : sortingOptions;
    if (!sortParam) {
      return null;
    }

    return (
      <div>
        <span className="sortByText">
          <FormattedMessage id="sortBy" />
        </span>
        <Select
          value={sortParam}
          MenuProps={MenuProps}
          className={`sortByList font16 textColorHeading`}
          onChange={event => {
            smoothScroll();
            let pageName = location.pathname;
            sendEventStats(
              {
                arg3: pageName,
                arg1: event.target.value
              },
              "macroSort",
              email
            );
            return setSortParam(event.target.value);
          }}
        >
          {listOptions.map(option => (
                <MenuItem
                key={option.key}
                value={option.key}
              className="headerSelectItem"
            >
                  {option.value}
              </MenuItem>
              ))}
        </Select>
      </div>
    );
  };

  const userOptions = () =>
    isOwner && (
      <div className="flex vrtlcenter spaceBetween margin-bottom-25">
      <div className="flex buttonContainer">
        <button
          className="deleteBtn margin-right-15"
            onClick={() => showDeleteModal(true)}
        >
          <FormattedMessage id="delete" />
        </button>
        <Link
          to={{
            pathname: "/uploadMacro",
            state: { activeMacro: selectedMacro }
          }}
          className="editBtn"
        >
          <FormattedMessage id="editUpload" />
        </Link>
      </div>
      </div>
    );

  const loadUserInfo = e => {
    const userData = e.detail.split("@@");
    window.removeEventListener("otsSuccess", loadUserInfo);
    dispatch(getMacros(email, null, null, sortBy, null, true, true));
  };

  useEffect(() => {
    const { pathname } = location;
    const isDetailsPage = pathname.indexOf("macroDetails") !== -1;
    const user = urlParams.get("email");
      if (user && macroList) {
        if (isDetailsPage) {
          history.push({
            pathname: pathname,
            search: `?email=${user}`
          });
      } else {
          history.push({
          pathname: "/",
          search: `?email=${user}`
          });
        }
        setUserUploads(true);
        if(!searchQuery) {
          dispatch(getMacros(email, null, null, sortBy, null, true, false, user, true));
        }
      }
      if (searchTerm && macroList) {
        if (appPkg) {
          filterMacroslist(searchTerm, appPkg);
        } else {
          filterMacroslist(searchTerm);
        }
      }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm && !appPkg) {
      //ADD Recommended to options
      setRecommendedOption(true);

      //Dispatch sort by recommedation option
      dispatch(setSortParameter("recommended"));
    } else {
      //Remove the Recommendation from options
      setRecommendedOption(false);

      //Dispatch sort by download count if current selected value is Recommended
      if (selectedSortingValue === "recommended") {
        dispatch(setSortParameter("download_count"));
      }      
    }
  }, [searchTerm]);

  const goToPage = (link, text) => {
    if (email) {
      history.push(link);
      if (text && text === "myUploads") {
        dispatch(showLoader());
        dispatch(getMacros(email, null, null, sortBy, null, loader, true, email, true));
        ReactGA.event({
          category: category,
          action: "clicked on myUploads",
          label: "myUploads"
        });
      } else if (text && text === "upload") {
        ReactGA.event({
          category: category,
          action: "clicked on upload macro",
          label: "uploadMacro"
        });
      }
    } else {
      setRedirectLink(link);
      if (!window.GmApi.isAvailable()) {
        showSignInModal(true);
      } else {
        window.addEventListener("otsSuccess", loadUserInfo);
        showOts(true);
        showSignInModal(true);
      }
    }
  };

  const navToSupport = link => {
    if (window.GmApi.isAvailable()) {
      window.GmApi.gmShowWebPage(
        intl.formatMessage({ id: "macroHelpTitle" }),
        link
      );
    } else {
      window.open(link);
    }
    ReactGA.event({
      category: category,
      action: "clicked on help icon",
      label: "support icon"
    });
  };

  const handleBack = () => {
    dispatch(showLoader());
    dispatch(setEndOfMacros(false));
    if (!user) {
      const uploaderEmail = new URLSearchParams(window.location.search).get("email");
      if (uploaderEmail) {
        setUser(new URLSearchParams(window.location.search).get("email"));
      }
      else {
        setUser(null);
      }
    }
    setUserUploads(false);
    dispatch(setSearchedString(false));
    setSearchResults(false);
    dispatch(setSearchQuery(null));
    if(history.length === 1){
      goToHome("/"); 
    }
    else{
      history.goBack();
    }
    dispatch(hideLoader());
  };

  const handleBackClick = () => {
    dispatch(setSearchQuery(null));
    if(history.length === 1){
      goToHome("/"); 
    }
    else {
      history.goBack();
    }
  };

  const leftSubHeader = backFunction => {
    const user = urlParams.get("email");
    const searchTerm = urlParams.get("searchTerm");
    return (
    <>
        <span className="flex vrtlCenter pointer" onClick={backFunction}>
        <span className="backArrow"></span>
        <span className="linkColor font18 margin-right-15">
            {location.pathname.toLowerCase().includes("/macrodetails") &&
              user &&
              selectedMacro && !searchTerm && (
            <FormattedMessage
              id="backToUploads"
              values={{ userName: selectedMacro.uploader_nickname }}
            />
              )}
              {location.pathname.toLowerCase().includes("/macrodetails") &&
              user &&
              selectedMacro && searchTerm && (
                <FormattedMessage
                  id="back"
                />
              )}
            {location.pathname.toLowerCase().includes("/macrodetails") &&
              !user && <FormattedMessage id="back" />}
            {!location.pathname.toLowerCase().includes("/macrodetails") && (
              <FormattedMessage id="back" />
            )}
        </span>
      </span>
      <span className="seperator margin-right-15"></span>
      <span className="font18 textColorHeading bold">
          {location.pathname.toLowerCase().includes("/macrodetails") && (
            <FormattedMessage id="macroDetails" />
          )}
          {location.pathname.toLowerCase().includes("/allgames") && (
            <FormattedMessage id="all_games" />
          )}
          {!location.pathname.toLowerCase().includes("/allgames") &&
            !location.pathname.toLowerCase().includes("/macrodetails") &&
            user && (
              <FormattedMessage
                id="uploads"
                values={{ userName: macros.length && macros[0].uploader_nickname }}
              />
            )}
          {location.pathname.toLowerCase().includes("/macrolist") && (
          <>
                <span>{macros.length}</span>{" "}
                {searchCount && (
          <>
                    <FormattedMessage id="resultsFor" />{" "}
                    <span>
                      "
                      {/* {location.pathname.toLowerCase().includes("/macroList") && ( */}
                        <span
                          dangerouslySetInnerHTML={{ __html: gameName }}
                        ></span>
                      {/* )} */}
                      {/* {!location.pathname.toLowerCase().includes("/macroList") && (
                        <span
                          dangerouslySetInnerHTML={{ __html: defaultSearchValue }}
                        ></span>
                      )} */}
                      
                      "
                    </span>
                  </>
                )}
            {!searchCount && <FormattedMessage id="resultFound" />}
          </>
        )} 
      </span>
    </>
    );
  };

  useEffect(() => {
    if (!user) {
      const uploaderEmail = new URLSearchParams(window.location.search).get("email") || new URLSearchParams(location.search).get("email");
      if (uploaderEmail) {
        setUser(uploaderEmail);
      }
      else {
        setUser(null);
      }
    }
  });
  
  const refreshMacros = () => {
    history.push({
      pathname: "/",
      search: null
    });
    reloadMacros();
  }

  if (!showHeader) {
    return null;
  }

  return (
    <>
      <header ref={headerContainer}>
        <div className="flex spaceBetween vrtlCenter margin-bottom-10 header">
          <span
            onClick={refreshMacros}
            className="communityLink flex pointer vrtlCenter"
          >
            <span className="communityIcon margin-right-10"></span>
            <span className="flex column">
              <span className="font16 bold darkBluetext capitalize noWrapText">
                <FormattedMessage id="macroCommunity" />
              </span>
              <span className="font12 mediumGreyColor">
                <FormattedMessage id="shareMacros" />
              </span>
            </span>
          </span>
          <SearchBox filterMacroslist={filterMacroslist} />
          <div className="linksContainer">
            <LinkView
              text="upload"
              title={intl.formatMessage({ id: "upload" })}
              icon="upload"
              className="margin-right-15 pointer"
              link={"/uploadMacro"}
              goToPage={goToPage}
            />
            {location.pathname !== "/myUploads" ? (
              <LinkView
                text="myUploads"
                title={intl.formatMessage({ id: "myUploads" })}
                icon="myUploads"
                link={"/myUploads"}
                className="pointer"
                goToPage={goToPage}
              />
            ) : (
              <TextView
                className="flex vrtlCenter darkBluetext"
                text="myUploads"
                title={intl.formatMessage({ id: "myUploads" })}
                icon="myUploads_active"
              />
            )}
            <LinkView
              className="helpIcon pointer margin-left-15"
              link="https://support.bluestacks.com/hc/en-us/articles/360037460731"
              icon="helpIcon_active"
              goToPage={navToSupport}
            />
          </div>
        </div>
      </header>
      {gameName && macros && (
        <div
          className="flex vrtlCenter searchResultsSection padding-bottom-25 subHeaderSection"
          ref={subHeaderContainer}
          style={{top: top}}
        >
          <p className="flex vrtlCenter">
            {location.pathname.toLowerCase().includes("/macrodetails") ||
            location.pathname.toLowerCase().includes("/allgames") ? (
              leftSubHeader(handleBackClick)
            ) : location.pathname === "/myUploads" ? (
              <span className="font18 textColorHeading bold">
                <FormattedMessage id="your_macros" />
                  </span>
            ) : (
              leftSubHeader(handleBack)
            )}
          </p>
          {location.pathname.toLowerCase().includes("/macrodetails")
            ? userOptions()
            : sortMenu()}
        </div>
      )}
      {userUploads && !gameName && macros && macros.length > 0 && (
        <div
          className="flex vrtlCenter searchResultsSection padding-bottom-25 subHeaderSection"
          ref={subHeaderContainer}
          style={{top: top}}
        >
        <p className="flex vrtlCenter">
            {location.pathname.toLowerCase().includes("/macrodetails") ||
            location.pathname.toLowerCase().includes("/allgames") ? (
              leftSubHeader(handleBackClick)
            ) : location.pathname === "/myUploads" ? (
          <span className="font18 textColorHeading bold">
                <FormattedMessage id="your_macros" />
          </span>
            ) : (
              leftSubHeader(handleBack)
            )}
        </p>
          {location.pathname.toLowerCase().includes("/macrodetails")
            ? userOptions()
            : sortMenu()}
      </div>
      )}
      {!gameName && !userUploads && macros && macros.length > 0 && (
        <div
          className="flex vrtlCenter searchResultsSection padding-bottom-25 subHeaderSection"
          ref={subHeaderContainer}
          style={{top: top}}
        >
        <p className="flex vrtlCenter">
            {location.pathname === "/myUploads" ? (
              <span className="font18 textColorHeading bold">
                <FormattedMessage id="your_macros" />
                </span>
            ) : location.pathname.toLowerCase().includes("/macrodetails") ||
              location.pathname.toLowerCase().includes("/allgames") ? (
                  leftSubHeader(handleBackClick)
            ) : (
              <span className="font18 textColorHeading bold">
                <FormattedMessage
                  id="macrosCount"
                  values={{ count: macros_count }}
                />
                  </span>
            )}
        </p>
          {location.pathname.toLowerCase().includes("/macrodetails")
            ? userOptions()
            : sortMenu()}
      </div>
      )}
      {googleSignIn && (
        <GoogleSignIn
          showSignInModal={showSignInModal}
          link={redirectLink}
          ots={ots}
        />
      )}
      {deleteModal && (
          <DownloadMacroModal
            closeModal={() => showDeleteModal(false)}
            headingTextId="deleteMacroHeading"
            descriptionTextId="deleteMacroDescription"
          deleteMacro={true}
          continueCancel={() => deleteMacroById()}
          />
        )}
    </>
  );
};

export default injectIntl(Header);

const LinkView = ({ text, icon, className, link, goToPage, title }) => {
  return (
    <div
      onClick={() => goToPage(link, text)}
      className={`flex vrtlCenter ${className}`}
    >
      <span className={`uploadIcon margin-right-10 ${icon}`}></span>
      <span className="linkColor font16 bold truncate"
            title={title && title}>
        {text && <FormattedMessage id={text} />}
      </span>
    </div>
  );
};

const TextView = ({ className, text, icon, title }) => {
  return (
    <p className={`${className}`}>
      <span className={`uploadIcon margin-right-10 ${icon}`}></span>
      <span className="font16 bold truncate"
            title={title && title}>
        <FormattedMessage id={text} />
      </span>
    </p>
  );
};
