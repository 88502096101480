import axios from "axios";

const urlParams = new URLSearchParams(window.location.search);
const mod = urlParams.get("module") ? "https://macro-community-dot-bs3-appcenter-engg.uc.r.appspot.com" : null;
const host = window.GmApi.gmDevUrl() || mod || "https://cloud.bluestacks.com";

export const sendEventStats = async (macro, event_type, email) => {
  let bodyFormData = new FormData();
  const imageName = localStorage.getItem("imageName");
  bodyFormData.set("tag", event_type);
  bodyFormData.set("Email",email);
  bodyFormData.set("Guid", window.GmApi.gmGetGuid());
  bodyFormData.set("ClientVer", window.GmApi.gmGetClientVer());
  bodyFormData.set("EngineVer", window.GmApi.gmGetEngineVer());
  bodyFormData.set("MachineId", window.GmApi.gmGetMachineId());
  bodyFormData.set("Locale", window.GmApi.gmGetLocale());
  bodyFormData.set("oem", window.GmApi.gmGetOem());
  bodyFormData.set("image_name",imageName?imageName:"")

  if (macro) {
    const { game_name, app_pkg, content_id, content_name, arg1, arg2, arg3 } = macro;
    bodyFormData.set("Content_id", content_id);
    bodyFormData.set("Game_name", game_name);
    bodyFormData.set("App_pkg", app_pkg);
    bodyFormData.set("Content_name",content_name);
    if(arg1) {
      bodyFormData.set("arg1", arg1);
    }
    if(arg2) {
      bodyFormData.set("arg2", arg2);
    }
    if(arg3) {
      bodyFormData.set("arg3", arg3);
    }
  }
  try {
    await axios({
      method: "post",
      url: host + "/communitycontent/stats/macroStats",
      data: bodyFormData
    });
  } catch (error) {
    return error;
  }
};
