import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import GoogleLogin from "react-google-login";
import { getMacros, getSSOCredentials } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Google from "./google";
import constants from "../../../consts";
import { userDataTransformer } from "../../../transformers";

const GoogleSignIn = ({ showSignInModal, link, macroDetails, ots }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sortBy = useSelector((state) => state.apps.sort_by);
  const user = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    if (user !== null) {
      onGoogleResponse(user);
    }
  }, [user]);

  const onGoogleResponse = (response) => {
    if (response !== null) {
      const email_id = response.email;
      const id_token = response.id_token;
      const guid = window.GmApi.gmGetGuid();
      console.log("onGoogleResponse", response);
      dispatch({
        type: constants.USER_INFO_SUCCESS,
        payload: userDataTransformer({
          message: {
            email_data: email_id,
            avatar_url_data:
              "https://support.bluestacks.com/system/photos/360572064151/profile_image_397995625011_253423.png",
            nickname_data: email_id.split("@")[0],
            token_data: id_token,
          },
        }),
      });
      console.log("emailId gLogIn", email_id);
      dispatch(getMacros(email_id, null, null, sortBy, null, true, true));
      showSignInModal(false);
    } else {
      console.log("error");
    }
  };

  const handleClick = () => {
    showSignInModal(false);
  };

  return (
    <>
      <div className="modalContainer pointer" onClick={handleClick} />
      <div className="modalBody signInModal">
        <div className="flex vrtlCenter spaceBetween margin-bottom-20">
          <img
            alt="bluestacks"
            src={`${process.env.PUBLIC_URL}/images/bsLogo.png`}
            className="bsLogo"
          />
          <h2>
            <FormattedMessage id="bluestacksAccount" />
          </h2>
        </div>
        <div className="flex column perfectCenter">
          <img
            alt="login"
            src={`${process.env.PUBLIC_URL}/images/loginImg.png`}
            className="loginImg"
          />
          <h3>
            <FormattedMessage id="pleaseSignIn" />
          </h3>
        </div>
        {ots ? (
          <div
            className="flex perfectCenter pointer signInBtn"
            onClick={() => {}}
          >
            <div
              onClick={() => window.GmApi.gmPerformOts()}
              className="sign-in-button"
              prompt="select_account"
              onSuccess={onGoogleResponse}
              onFailure={onGoogleResponse}
            >
              <div className="googleLogo">
                <img
                  alt="google"
                  src={`${process.env.PUBLIC_URL}/images/logo_google.png`}
                />
              </div>
              <span className="font16 whiteText">
                <FormattedMessage id="signIn" />
              </span>
            </div>
          </div>
        ) : (
          <Google />
        )}
        <h6>
          <span className="createAccountText">
            <FormattedMessage id="ifYouDontHave" />
          </span>
        </h6>
      </div>
    </>
  );
};

export default GoogleSignIn;
