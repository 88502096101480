import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import DetailItem from "../common/DetailItem";
import CommonButton from "../common/CommonButton/CommonButton";
import DownloadMacroModal from "../common/DownloadMacroModal/DownloadMacroModal";
import GoogleSignIn from "../common/GoogleSignIn/GoogleSignIn";
import {
  likeDownloadMacro,
  setSearchQuery,
  searchResults
} from "../../actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { sendEventStats } from "../../actions/eventActions";
import { getMacros, setEndOfMacros } from "../../actions";
import ReactGA from 'react-ga';

const MacroListItem = ({ macro, showSearchResults, link, nickname, position, setUserUploads, user, setUser, resetSearch, uploaderEmail, userUploads, gameSearchResults, updateSort,intl }) => {
  const [downloadModal, showDownloadModal] = useState(false);
  const [isLikeClicked, likeClicked] = useState(false);
  const [googleSignIn, showSignInModal] = useState(false);
  const [ots, showOts] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric"
  };
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.user_email);
  const category = useSelector(state => state.apps.category);
  const sortBy = useSelector(state => state.apps.sort_by);
  const searchedString = useSelector(state => state.apps.searchedString);
  const macros = (gameSearchResults || userUploads || searchedString) ? "filteredMacros" : "macroList";
  const openModal = (event, args) => {
    if (event) {
      event.stopPropagation();
    }
    if (email) {
      if (window.GmApi.isAvailable()) showDownloadModal(true);
      else {
        dispatch(likeDownloadMacro(args.id, "download", email, macros));
        sendEventStats(macro, "macroDownload", email)
        document.getElementById("downloadMacro").click();
      }
      ReactGA.event({
        category: category,
        action: "download macro",
        label: macro.content_id
      });
    } else {
      if (!window.GmApi.isAvailable()) {
        showSignInModal(true);
      } else {
        showOts(true);
        showSignInModal(true);
      }
    }
  };

  const showMacroDetails = () => {
    sendEventStats(macro, "macroItemClick", email);
    const historyObj = {
      pathname: `/macroDetails/${macro.content_id}`,
      state: { link }
    };
    historyObj.search = location.search;
    history.push(historyObj);
    ReactGA.event({
      category: category,
      action: "show macro details",
      label: macro.content_id
    });
  };

  const likeMacro = (id, isLiked) => {
    if (email) {
      likeClicked(true);
      if (isLikeClicked) {
        return;
      }
      dispatch(likeDownloadMacro(id, "like", email, macros)).then(() =>
        likeClicked(false)
      );
      const macroLike = isLiked ? 'macroDislike' : 'macroLike';
      sendEventStats(macro, macroLike, email);
      ReactGA.event({
        category: category,
        action: macroLike,
        label: macro.content_name
      });
    } else {
      if (!window.GmApi.isAvailable()) {
        showSignInModal(true);
      } else {
        showOts(true);
        showSignInModal(true);
      }
    }
  };

  const showUserUploads = (event, user) => {
    if (event) event.stopPropagation();
    setUser(user);
    dispatch(getMacros(email, null, null, sortBy, null, true, true, user, true));
    dispatch(setEndOfMacros(false));
    setUserUploads(true);
    history.push({
      pathname: "/",
      search: `?email=${user}`
    });
    ReactGA.event({
      category: category,
      action: "Clicked on user nickname",
      label: "user nickname"
    });
  };

  const filterMacroslist = (event, query, appPkg) => {
    event.stopPropagation();
    dispatch(setSearchQuery(query));
    dispatch(searchResults(query));
    showSearchResults(query);
      history.push({
      pathname: `/macroList/${query}`,
      search: `?searchTerm=${query}&app_pkg=${appPkg}`
    });
    ReactGA.event({
      category: category,
      action: "Search Macro from list item",
      label: query
    });
  };

  return (
    <>
      <li className="flex spaceBetween macroDetails macroListItem" onClick={showMacroDetails}>
        <div className="flex macroInfo vrtlCenter">
          <img
            alt={macro.app_pkg}
            src={`https://cloud.bluestacks.com/app/icon?pkg=${macro.app_pkg}`}
          />
          <div className="flex column hideOverflow">
            <p className="flex column pointer macroDetailsLink">
              <span className="bold ellipsis font18 textColorHeading">
                {macro.content_name}
              </span>
              <span className="ellipsis font14 textColorSubHeading margin-bottom-10">
                {macro.content_description}
              </span>
            </p>
            <p className="flex macroName spaceBetween font12 lightGreyColor wrap">
              <span className="gameName">
                <span className="margin-right-5">
                  <FormattedMessage id="game" />
                </span>
                <span
                  className="linkColor pointer"
                  onClick={event => filterMacroslist(event, macro.game_name, macro.app_pkg)}
                  dangerouslySetInnerHTML={{__html: macro.game_name}}
                >
                </span>
              </span>
              <span className="authorName">
                <span className="margin-right-5">
                  <FormattedMessage id="author" />
                </span>
                <span
                  className="linkColor pointer"
                  onClick={event =>
                    showUserUploads(event, macro.uploader_email)
                  }
                >
                  {macro.uploader_nickname}
                </span>
              </span>
            </p>
          </div>
        </div>
        <div className="macroDownload flex column spaceBetween">
          <div className="flex vrtlCenter spaceBetween font14 textColorHeading downloadDetails">
            <DetailItem
              className="downloads pointer"
              value={macro.download_count ? macro.download_count : 0}
              onClick={event =>
                openModal(event, { id: macro.content_id })
              }
            />
            <DetailItem
              className={`likes ${
                isLikeClicked
                  ? ""
                  : macro.is_liked
                  ? "liked pointer"
                  : "pointer"
              }`}
              value={macro.likes_count ? macro.likes_count : 0}
              isLiked={macro.is_liked}
              id={macro.content_id}
              onClick={() => likeMacro(macro.content_id, macro.is_liked)}
            />
            <DetailItem
              className="comments"
              value={"comments"}
              id={macro.content_id}
              commentCount={macro.comment_count}
            />
            <CommonButton
              className={`downloadButton primaryBtn truncate ${
                macro.is_downloaded ? "disabled" : ""
              }`}
              text="get"
              title={intl.formatMessage({ id: "get" })}
              params={{ id: macro.content_id }}
              onClick={openModal}
            />
          </div>
          <p className="flex hrtlCenter font12 timestamp">
            <span className="margin-right-5 lightGreyColor">
              <FormattedMessage id="lastUpdated" />
            </span>
            <span className="mediumGreyColor">
              {new Intl.DateTimeFormat("indian", options).format(
                new Date(macro.modified_at)
              )}
            </span>
          </p>
        </div>
      </li>
      {downloadModal && (
        <DownloadMacroModal
          macro={macro}
          macroName={macro.content_name}
          download_link={macro.download_link}
          macroId={macro.content_id}
          closeModal={() => showDownloadModal(false)}
          nickName={macro.uploader_nickname}
          uploaderEmail={macro.uploader_email}
          headingTextId="importMacro"
          descriptionTextId="areYouSure"
          macros={macros}
        />
      )}
      <a
        id="downloadMacro"
        href={macro.download_link}
        download={macro.content_name}
      >
        Download
      </a>
      {googleSignIn && <GoogleSignIn showSignInModal={showSignInModal} ots={ots} />}
    </>
  );
};

export default MacroListItem;
