import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from 'react-ga';
import { FormattedMessage } from "react-intl";
import { sendEventStats } from "../../actions/eventActions";

const AllGamesItem = ({
    indexKey,
    macroCount,
    gameName,
    appPackage
}) => {
    const history = useHistory(),
        imgLoadUrl = "https://cloud.bluestacks.com/app/icon",
        category = useSelector(state => state.apps.category),
        email = useSelector(state => state.user.user_email),
        filterRecommendation = (query, pkg, type) => {
            if (type === "recommendation") {
                sendEventStats({
                    app_pkg:pkg,
                    game_name:query
                }, 
                    "macroGameCard",
                    email
                );
                history.push({
                pathname: `/macroList/${query}`,
                search: `?searchTerm=${query}${pkg ? `&app_pkg=${pkg}` : "" }`
              });
              ReactGA.event({
                category: category,
                action: "Search Recommendation Link Click",
                label: query
              });
            }
          };
    
    function trimStartAndEndWhiteSpaces (inputString){
        return inputString.trim();
    }

    return (
        <>
            <div className="allGamesItem"
                key={indexKey}
                onClick={() => filterRecommendation(gameName, appPackage, 'recommendation')}>
                <img className="gameImg" 
                    alt=""
                    src={`${imgLoadUrl}?pkg=${appPackage}`} />
                <div className="textGroup">
                    <p className="gameTitle truncate"
                        dangerouslySetInnerHTML={{__html: trimStartAndEndWhiteSpaces(gameName)}}></p>
                    <span className="macroCount">
                        <FormattedMessage id="macrosCount" 
                                        values={{ count: macroCount }}/>
                    </span>
                </div>
            </div>
        </>
    )
}

export default AllGamesItem;