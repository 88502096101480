import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import consts from "../../../consts";

const DisqusBox = ({ id, disqusData, intl }) => {
  const sso = useSelector(state => state.apps.sso);
  const instance = useSelector(state => state.apps.instance);
  const url = instance === "prod" ? consts.prodUrl : consts.enggUrl;
  useEffect(() => {
    const disqus_identifier = `macroDetails/${id}`;
    const disqus_url = `${url}/macroDetails/${id}`;
    (function() {
      if (disqusData) {
        const locale = intl.locale.split("-")[0];
        window.DISQUS.reset({
          reload: true,
          config: function() {
            this.page.identifier = disqus_identifier;
            this.page.url = disqus_url;
            this.language = locale;
            if (sso) {
              this.page.remote_auth_s3 = sso.sig;
              this.page.api_key = sso.pub_key;
            }
          }
        });
        var hideShareLinks = document.createElement("p");
        hideShareLinks.className = "hideSpace shareLinks";
        document.getElementById("disqus_thread").appendChild(hideShareLinks);
      }
    })();
  }, [id, sso, disqusData]);

  return (
    <div id="disqus_thread">
      
    </div>
  );
};

export default injectIntl(DisqusBox);
