import React from "react";
import {FormattedMessage} from "react-intl";

const CommonButton = ({className, text, onClick, params,title}) => {
    return (
        <button className={`primaryBtn ${className}`} onClick={(event) => handleClick(event, onClick, params)} title={title && title}><FormattedMessage id={text} /></button>
    )
};

export default CommonButton;

const handleClick = (event, clickAction, params) => {
    return clickAction ? clickAction(event, params) : null;
};
