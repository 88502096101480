import React,{useState} from "react";
import { injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import { setSearchQuery } from "../../../actions/commonActions";
import { setSearchedString, setSortParameter } from "../../../actions";


const SearchBox = ({filterMacroslist, intl}) => {
    const dispatch = useDispatch();
    const [fromSearchQuery, setFromSearchQuery] = useState(false);
    const gameName = useSelector(state => state.apps.gameName);
    const searchQuery = useSelector(state => state.apps.searchQuery);
    let defaultSearchQuery = fromSearchQuery ? searchQuery : gameName;
    const setQuery = () => {
        setFromSearchQuery(true);
        const searchQuery = DOMPurify.sanitize(document.querySelector(".searchInput").value);
        dispatch(setSearchQuery(searchQuery));
    }
    const handleSubmit = event => {
        event.preventDefault();
        defaultSearchQuery = defaultSearchQuery.trim();
        if (!defaultSearchQuery) {
            return;
        }
        dispatch(setSortParameter("recommended"));
        //setFromSearchQuery(false);
        filterMacroslist(defaultSearchQuery,null, 'searchbox');
        dispatch(setSearchedString(true));

    }
    return (
        <div className="searchBox margin-right-10">
            <form className="flex" onSubmit={handleSubmit} id="searchForm">
                <input type="text" className="searchInput font16" onChange={setQuery} value={`${defaultSearchQuery ? defaultSearchQuery.replace('&#39;', "'") : ""}`} placeholder={intl.formatMessage({ id: "searchMacro" })} />
                <input type="submit" className={`searchIcon ${defaultSearchQuery ? "searchButtonIcon " : "searchButtonIconDisabled"}`} value=""></input>
            </form>
        </div>
    )
};

export default injectIntl(SearchBox);
