import React from "react";
import { FormattedMessage } from "react-intl";
import CommonButton from "../CommonButton/CommonButton";
import {
  downloadMacro,
  likeDownloadMacro
} from "../../../actions/commonActions";
import { sendEventStats } from "../../../actions/eventActions";
import { useDispatch, useSelector } from "react-redux";

const DownloadMacroModal = ({
  closeModal,
  macroName,
  download_link,
  macroId,
  nickName,
  headingTextId,
  descriptionTextId,
  deleteMacro,
  continueCancel,
  uploaderEmail,
  macro,
  macros
}) => {
  const instance = useSelector(state => state.apps.instance);
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.user_email);
  const params = {
    macroName: macroName,
    download_link: download_link,
    id: macroId,
    nickName: nickName,
    email: uploaderEmail,
    instance: instance
  };
  
  const downloadCurrentMacro = (event, args) => {
    dispatch(likeDownloadMacro(args.id, "download", email, macros)).then(() =>
      closeModal()
    );
    sendEventStats(macro, "macroDownload", email);
    downloadMacro(args);
  };
  return (
    <>
      <div className="modalContainer" onClick={closeModal} />
      <div className="modalBody">
        <div className="flex spaceBetween margin-bottom-10 vrtlCenter">
          <p className="font20 bold darkBluetext">
            <FormattedMessage id={headingTextId} />
          </p>
          <span className="crossIcon pointer" onClick={closeModal}></span>
        </div>
        <p className="font14 margin-bottom-20 textColorHeading">
          <FormattedMessage id={descriptionTextId} values={{ macroName: macroName }} />
        </p>
        <div className="flex alignRight">
          <button
            className="cancelBtn font16 bold margin-right-10"
            onClick={closeModal}
          >
            <FormattedMessage id="cancel" />
          </button>
          <CommonButton
            className="continueButton primaryBtn"
            text="continue"
            onClick={deleteMacro ? continueCancel : downloadCurrentMacro}
            params={params}
          />
        </div>
      </div>
    </>
  );
};

export default DownloadMacroModal;
