import consts from "../consts";

const appReducer = (
  state = {
    sort_by: "download_count",
    sort_by_all_games:"macro_count", 
    macros: [], 
    end_of_macros: false, 
    backFlag: false,
    filteredMacros: [],
    macroDetailSuccess: true,
    authorsEmail: null
    },
    action
  ) => {
  const { type, payload } = action;
  switch (type) {
    case consts.RESET_MACROS: {
      return {
        ...state,
        macros: [],
        cursor: undefined,
        loader: true
      };
    }
    case consts.END_OF_MACROS: {
      return {
        ...state,
        end_of_macros: payload
      };
    }
    case consts.MACROS_DATA_SUCCESS: {
      const macrosList = [...state.macros, ...payload.macros];
      return {
        ...state,
        macros: macrosList,
        cursor: payload.cursor,
        loader: false,
        total_macros: payload.total_macros
      };
    }
    case consts.MACROS_SEARCH_DATA_SUCCESS:{
      return {
        ...state,
        filteredMacros: [...state.filteredMacros, ...payload.filteredMacros],
        cursor: payload.cursor,
        loader: false,
        total_macros: payload.total_macros
      }
    }
    case consts.SET_SORTED_MACROS: {
      return {
        ...state,
        [payload.type]: payload.macros
      }
    }
    case consts.SEARCHED_STRING:{
      return {
        ...state,
        searchedString: payload
      }
    }
    case consts.GET_GAMES_SUCCESS: {
      return {
        ...state,
        gamesInstalled: payload
      }
    }
    case consts.SET_QUERY: {
      return {
        ...state,
        searchQuery: payload
      };
    }
    case consts.SET_SELECTED_MACRO: {
      return {
        ...state,
        selectedMacro: payload
      };
    }
    case consts.SET_SEARCH_QUERY: {
      return {
        ...state,
        defaultSearchValue: payload
      };
    }
    case consts.SHOW_FILTERED_MACROS: {
      if (payload) {
        return {
          ...state,
          filteredMacros: state.macros.filter(macro => {
            return (
              macro.game_name.toLowerCase() === payload.toLowerCase()
            );
          })
        };
      }
      return {
        ...state,
        filteredMacros: state.macros.filter(macro => {
          return (
            macro.content_name
              .toLowerCase()
              .indexOf(state.defaultSearchValue.toLowerCase()) !== -1 ||
            macro.content_description
              .toLowerCase()
              .indexOf(state.defaultSearchValue.toLowerCase()) !== -1 ||
            macro.game_name
              .toLowerCase()
              .indexOf(state.defaultSearchValue.toLowerCase()) !== -1 ||
            (macro.content_tags &&
              macro.content_tags
                .toLowerCase()
                .indexOf(state.defaultSearchValue.toLowerCase()) !== -1) ||
            macro.app_pkg
              .toLowerCase()
              .indexOf(state.defaultSearchValue.toLowerCase()) !== -1
          );
        })
      };
    }
    case consts.MACROS_DELETE_SUCCESS: {
      return {
        ...state,
        macros: state.macros.filter(macro => {
          return macro.content_id !== payload;
        }),
        loader: false
      };
    }
    case consts.MACRO_LIKE_SUCCESS: {
      if (payload.macros==="filteredMacros") {
        return {
          ...state,
          filteredMacros: state.filteredMacros.map(macro => {
            if (macro.content_id === payload.id) {
              if (macro.is_liked) {
                return {
                  ...macro,
                  ...(macro.is_liked = false),
                  ...(macro.likes_count = macro.likes_count - 1)
                };
              }
              return {
                ...macro,
                ...(macro.is_liked = true),
                ...(macro.likes_count = macro.likes_count + 1)
              };
            } else return macro;
          })
        };
      }
      return {
        ...state,
        macros: state.macros.map(macro => {
          if (macro.content_id === payload.id) {
            if (macro.is_liked) {
              return {
                ...macro,
                ...(macro.is_liked = false),
                ...(macro.likes_count = macro.likes_count - 1)
              };
            }
            return {
              ...macro,
              ...(macro.is_liked = true),
              ...(macro.likes_count = macro.likes_count + 1)
            };
          } else return macro;
        })
      };
    }
    case consts.MACRO_DOWNLOAD_SUCCESS: {
      if (payload.macros==="filteredMacros") {
        return {
          ...state,
          filteredMacros: state.filteredMacros.map(macro => {
            if (macro.content_id === payload.id) {
              return {
                ...macro,
                ...(macro.is_downloaded = true),
                ...(macro.download_count = macro.download_count + 1)
              };
            } else return macro;
          })
        };
      }
      return {
        ...state,
        macros: state.macros.map(macro => {
          if (macro.content_id === payload.id) {
            return {
              ...macro,
              ...(macro.is_downloaded = true),
              ...(macro.download_count = macro.download_count + 1)
            };
          } else return macro;
        })
      };
    }
    case consts.SSO_SUCCESS: {
      return {
        ...state,
        sso: payload
      };
    }
    case consts.MACROS_UPLOAD_SUCCESS: {
      return {
        ...state,
        macros: [...state.macros, payload],
        loader: false
      };
    }
    case consts.MACROS_EDIT_SUCCESS: {
      return {
        ...state,
        macros: state.macros.map(macro => {
          if (macro.content_id === payload.content_id) {
            return {
              ...payload
            };
          } else return macro;
        }),
        loader: false
      };
    }
    case consts.SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case consts.HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case consts.SET_INSTANCE: {
      return {
        ...state,
        instance: payload
      };
    }
    case consts.SHOW_USER_FILTERED_MACROS: {
      return {
        ...state,
        filteredMacros: state.macros.filter(macro => {
          return macro.uploader_email === payload
        })
      }
    }
    case consts.SET_CATEGORY: {
      return {
        ...state,
        category: payload
      }
    }
    case consts.SORT_STATUS:{
      return{
        ...state,
        sort_by: payload
      }
    }
    case consts.SORT_STATUS_ALL_GAMES:{
      return{
        ...state,
        sort_by_all_games: payload
      }
    }
    case consts.GAME_NAME: {
      return {
        ...state,
        gameName: payload
      };
    }
    case consts.SEARCH_RECOMMENDATION_SUCCESS: {
      return {
        ...state,
        search_recommendation: payload.search_recommendation
      }
    }
    case consts.ALL_GAMES_SUCCESS: {
      return {
        ...state,
        all_games: payload.all_games
      }
    }
    case consts.RESET_FILTERED_MACROS: {
      return {
        ...state,
        filteredMacros: [],
        cursor: undefined,
        loader: true
      }
    }
    case consts.MACRO_DETAILS_SUCCESS: {
      return {
        ...state,
        selectedMacro: payload ? payload[0] : null,
        macroDetailSuccess: true
      };
    }
    case consts.MACRO_DETAILS_FETCH: {
      return {
        ...state,
        macroDetailSuccess: false
      }
    }
    default:
      return state;
  }
};

export default appReducer;
