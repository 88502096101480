import React from "react";
import Label from "../Label/Label";
import InputField from "../InputField/InputField";

const InputContainer = ({type, className, onChange, label, value, required, placeholder, maxLength, minLength}) => {
    return (
        <>
            <Label className="font14 mediumGreyColor margin-bottom-10" text={label} />
            <InputField placeholder={placeholder} type={type} value={value} className={className} onChange={onChange} required={required} maxLength={maxLength} minLength={minLength} />
        </>
    )
};

export default InputContainer;
