import React from "react";

const DetailItem = ({ className, value, id, onClick, commentCount }) => {
  const handleClick = e => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  }

  return (
    <span className={`flex vrtlCenter margin-right-15 ${className}`} onClick={(e) => handleClick(e)}>
      <span className={`smallIcon margin-right-10 ${className}`}></span>
      {value !== "comments" ? (
        <span className="count">{value}</span>
      ) : (
        <div
          className="disqus-comment-count"
        >{commentCount}</div>
      )}
    </span>
  );
};

export default DetailItem;
