import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../selectors";
import MacroListItem from "./MacroListItem";
import CommonSection from "../common/CommonSection/CommonSection";
import Loader from "../common/Loader/Loader";
import { useHistory, useLocation } from "react-router-dom";
import GoogleSignIn from "../common/GoogleSignIn/GoogleSignIn";
import SidePanel from "../common/SidePanel/SidePanel";
import { setSortedMacros, showLoader, setEndOfMacros } from "../../actions";
import { setGameName } from "../../actions/commonActions";
import { getMacros } from '../../actions';
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";

const MacroList = ({
  resetSearch,
  searchResults,
  showSearchResults,
  searchCount,
  setUserUploads,
  userUploads,
  user,
  setUser,
  intl
}) => {
  let macroList = useSelector(state => selectors.macrosSelector(state));
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const gameNameSelector = useSelector(state=>state.apps.gameName);
  const gameName=gameNameSelector || urlParams.get("searchTerm");
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.user_email);
  const cursor = useSelector(state => state.apps.cursor);
  const query = useSelector(state => state.apps.searchQuery);
  const selectedSortingValue = useSelector(state => state.apps.sort_by);
  const filteredMacros = useSelector(state => state.apps.filteredMacros);
  const searchedString = useSelector(state => state.apps.searchedString)
  const macros =
    selectedSortingValue && (gameName || userUploads)
      ? filteredMacros
      : macroList;
  const macrosType = gameName || userUploads ? "filteredMacros" : "macros";
  const [sortValue, updateSort] = useState(null);
  const defaultSearchValue = useSelector(
    state => state.apps.defaultSearchValue
  );
  const loader = useSelector(state => state.apps.loader);
  const end_of_macros = useSelector(state => state.apps.end_of_macros);
  const [googleSignIn, showSignInModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const history = useHistory();
  const macroListContainer = useRef(null);
  const sidePanel = useRef(null);
  const [top, resetOffsetTop] = useState(0);
  let offsetTop;
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [sortingValue,setSortingValue] = useState(null);

  const onClick = () => {
    if (email) {
      history.push("/uploadMacro");
    } else {
      if (!window.GmApi.isAvailable()) {
        setRedirectLink("/uploadMacro");
        showSignInModal(true);
      }
    }
  };

  function updateOffSetTop() {
    offsetTop =
      macroListContainer &&
      macroListContainer.current &&
      macroListContainer.current.offsetTop;
    resetOffsetTop(offsetTop);
  }

  useEffect(() => {
    setHasMoreItems(true);
    if (!user) {
      setUser(new URLSearchParams(window.location.search).get("user"));
    }
    updateOffSetTop();
    window.addEventListener("resize", updateOffSetTop);
    return () => {
      window.removeEventListener("resize", updateOffSetTop);
      dispatch(setGameName(""));
    };
  }, [ macros ]);


  const showMoreMacros = () => {
    return macros
      .map((macro, index) => (
        <MacroListItem
          key={macro.content_id}
          macro={macro}
          showSearchResults={showSearchResults}
          link="back"
          position={index}
          setUserUploads={setUserUploads}
          user={user}
          setUser={setUser}
          resetSearch={resetSearch}
          gameSearchResults={searchResults}
          userUploads={userUploads}
          updateSort={updateSort}
          intl={intl}
        />
      ));
      
  };
  const loadMoreMacros = () => {
    if (end_of_macros) {
       setHasMoreItems(false);
    } else {
        const backEmail = urlParams.get("email");
        dispatch(getMacros(email, null, null, selectedSortingValue, cursor, loader, false, searchedString ? backEmail : null));
    }
  };


  useEffect(() => {
    if (sortingValue) {
      if(selectedSortingValue === "recommended") {
        dispatch(setEndOfMacros(false));
        dispatch(getMacros(email, gameName, null, selectedSortingValue, null, true, true));
      } else {
        dispatch(setEndOfMacros(false));
        const backEmail = urlParams.get("email");
        dispatch(getMacros(email, gameName, null, selectedSortingValue, null, true, true, backEmail, true));
        if(backEmail || gameName) {
          dispatch(getMacros(email, null, null, selectedSortingValue, null, false, true));
        }
      }
      dispatch(setSortedMacros([], macrosType));
      
    }
    setSortingValue(selectedSortingValue);
  }, [ selectedSortingValue, sortValue ]);

  useEffect(() => {
    if (gameName) {
      dispatch(setGameName(gameName));
    } else {
      dispatch(setGameName(""));
    }
  });

  if (loader) {
    return <Loader />;
  }
  if (!macros) {
    return null;
  }
  if(query) {
    if(macros.length === 0 && !end_of_macros) {
      dispatch(showLoader());
    }
  }
  if(!query) {
    if(macros.length === 0 && !end_of_macros) {
      dispatch(showLoader());
    }
  }

  return (
    <>
      <div className="flex start" ref={macroListContainer}>
        <div className="sidePanel" ref={sidePanel} style={{ top: top }}>
          <SidePanel showSearchResults={showSearchResults} />
        </div>
        <ul className="macroList macroListItems">
          {!macros.length && (
            <CommonSection
              searchResults={searchResults}
              searchField={`"${defaultSearchValue}"`}
              heading={searchResults ? "noResults" : "noMacrosFound"}
              subHeading={searchResults ? "tryUsing" : "haveMacros"}
              img={`${process.env.PUBLIC_URL}/images/macro_error.svg`}
              btnText="uploadNow"
              onClick={onClick}
            />
          )}
          {macros.length > 0 && (
            <InfiniteScroll
              loadMore={loadMoreMacros}
              hasMore={hasMoreItems}
              loader={loader ? <Loader /> : null }
              useWindow={true}
              threshold={400}
            >
              {showMoreMacros()}
            </InfiniteScroll>
          )}
        </ul>
      </div>
      {googleSignIn && (
        <GoogleSignIn showSignInModal={showSignInModal} link={redirectLink} />
      )}
    </>
  );
};

export default injectIntl(MacroList);
