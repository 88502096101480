import consts from "../consts";
const INITIAL_STATE = {
  userInfo: null,
  gsiInit: false
}
const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case consts.USER_INFO_SUCCESS: {
      return {
        ...state,
        ...payload
      };
    }
    case consts.EDIT_NAME_SUCCESS: {
      return {
        ...state,
        user_nickname: payload
      };
    }
    case consts.USER_INFO: {
      return {
        ...state,
        userInfo: payload
      }
    }
    default:
      return state;
  }
};

export default userReducer;
