import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const Google = () => {
  /* global google */
  const isSignedIn = useSelector((state) => state.apps.userInfo !== null);

  const gsiButton = useRef(null);

  useEffect(() => {
    // if (!isSignedIn && gsiInit) {
    google.accounts.id.renderButton(gsiButton.current, {
      type: "standard",
      theme: "filled_blue",
      size: "medium",
      text: "signin_with",
    });
    // }
  }, []);

  return <>{<div ref={gsiButton} id="buttonDiv" />}</>;
};

export default Google;
