import React, { useState, useEffect } from "react";
import MacroList from "./MacroList/MacroList";
import MacroDetails from "./MacroDetails/MacroDetails";
import { HashRouter, Route,Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./common/Header/Header";
import history from "../history";
import ReactGA from 'react-ga';
import {
  getMacros,
  getSearchRecommendations,
  getAllGames,
  showFilteredMacros,
  getSSOCredentials,
  setSearchedString,
  setEndOfMacros,
  showLoader,
  resetMacrosData,
  resetFilteredMacrosData,
  setSortParameter
} from "../actions";
import { goToHome, setSearchQuery, searchResults as getSearchResults, setUserInfo } from "../actions/commonActions";
import MyUploads from "./MyUploads/MyUploads";
import UploadMacro from "./UploadMacro/UploadMacro";
import consts from "../consts";
import { sendEventStats } from "../actions/eventActions";
import AllGames from "./AllGames/AllGames";
import jwt_decode from "jwt-decode";
import { userDataTransformer } from "../transformers";

const App = () => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState(false);
  const [header, showHeader] = useState(true);
  const [defaultSearch, showDefaultSearch] = useState(true);
  const [disqusData, disqusLoaded] = useState(false);
  const [searchCount, showSearchCount] = useState(true);
  const [userUploads, setUserUploads] = useState(false);
  const [user, setUser] = useState(null);
  const [sortOnAllGames,showSortOnAllGames] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.user_email);
  const guid = window.GmApi.gmGetGuid();
  const userDetails = JSON.parse(window.GmApi.gmGetUserToken());
  console.log("userDetails", userDetails);
  const emailId = userDetails.email;
  const token = userDetails.token;
  const sortBy = useSelector(state => state.apps.sort_by);

  const loadDisqusData = () => {
    disqusLoaded(true);
  };

  const loadUserInfo = e => {
    const userData = e.detail.split("@@");
    window.removeEventListener("otsSuccess", loadUserInfo);
    dispatch(getMacros(email, null, null, sortBy, null, true, true));
    dispatch(getSearchRecommendations());
    dispatch(getAllGames());
  };

  useEffect(() => {
    dispatch({
      type: consts.USER_INFO_SUCCESS,
      payload: userDataTransformer({
        message: {
          email_data: emailId,
          avatar_url_data:
            "https://support.bluestacks.com/system/photos/360572064151/profile_image_397995625011_253423.png",
          nickname_data: emailId.split("@")[0],
        },
      }),
    });
    console.log("emailId automatic", emailId);
    const category = window.GmApi.isAvailable() ? "appPlayer" : "web";
    dispatch({
      type: consts.SET_CATEGORY,
      payload: category
    });
    const trackingId = window.location.href.indexOf("engg") === -1 ? "UA-154720945-1" : "UA-154732247-1";

    let d = document,
      s = d.createElement("script");
    s.src = "https://bluestacks-community.disqus.com/embed.js";
    s.setAttribute("data-timestamp", +new Date());
    s.id = "dsq-embed-scr";
    s.async = true;
    (d.head || d.body).appendChild(s);
        s.onload = function() {
      loadDisqusData();
    };
    window.addEventListener("otsSuccess", loadUserInfo);
    window.GmApi.gmGetImageName();
    sendEventStats(null, "macroCommunityImpression", emailId);
    const urlParams = new URLSearchParams(window.location.search);
    const instance = urlParams.get("instance");
    const pkg  = urlParams.get("pkg");
    dispatch({
      type: consts.SET_INSTANCE,
      payload: instance
    });
    dispatch(getMacros(email, null, null, sortBy, null, true, true));
    dispatch(getSearchRecommendations());
    dispatch(getAllGames());
    if (window.location.href.indexOf("localhost") === -1) {
      ReactGA.initialize(trackingId);
      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  }, []);

  const resetSearch = () => {
    dispatch(setSearchedString(false));
    dispatch(setSearchQuery(""));
    setSearchResults(false);
    showDefaultSearch(true);
    setQuery("");
    setUserUploads(false);
    showSortOnAllGames(false);
  };

  const reloadMacros = () => {
    setUser(null);
    dispatch(setSearchedString(false));
    dispatch(setSearchQuery(""));
    goToHome("/");
    window.scrollTo(0, 0);
    dispatch(setEndOfMacros(false));
    dispatch(resetMacrosData());
    dispatch(resetFilteredMacrosData());
    dispatch(setSortParameter("download_count"));
    dispatch(getMacros(email, null, null, "download_count", null, true, true));
    dispatch(showLoader());
    dispatch(getSearchRecommendations());
    dispatch(getAllGames());
    setSearchResults(false);
    showDefaultSearch(true);
    setQuery("");
    setUserUploads(false);
    showSortOnAllGames(false);
  };

  const showSearchResults = game => {
    setSearchResults(true);
    showDefaultSearch(false);
    showSearchCount(true);
    setUserUploads(false);
    showSortOnAllGames(false)
  };

  window.onGoogleLibraryLoad = () => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "602184848161-bth51tf7vfp9hdkpagdnnlpi3jjvfql2.apps.googleusercontent.com",
      auto_select: false,
      cancel_on_tap_outside: false,
      context: "signin",
      callback: handleCallbackResponse,
    });
  }

  const handleCallbackResponse = async (res) => {
    if (!res.clientId || !res.credential) return;
    const decoded_credential = jwt_decode(res.credential);

    const credential = {
      id_token: res.credential,
      ...decoded_credential,
    };

    dispatch(setUserInfo(credential));
  }
  return (
    <div className="container">
      <div className="appHome">
        <HashRouter history={history}>
          <Route
            path="/"
            render={() => (
              <Header
                resetSearch={resetSearch}
                searchResultList={searchResults}
                userUploads={userUploads}
                searchCount={searchCount}
                showHeader={header}
                reloadMacros={reloadMacros}
                showSearchResults={showSearchResults}
                query={query}
                setUserUploads={setUserUploads}
                showSortOnAllGames={sortOnAllGames}
                user={user}
                setUser={setUser}
                setSearchResults={setSearchResults}
              />
            )}
          />
          <Route
            // exact
            path="/macroList"
            render={() => (
              <MacroList
                resetSearch={resetSearch}
                searchResults={searchResults}
                showSearchResults={showSearchResults}
                searchCount={searchCount}
                setUserUploads={setUserUploads}
                userUploads={userUploads}
                user={user}
                setUser={setUser}
              />
            )}
          />
          {/* <Route
            exact
            path="/macroList/:gameName"
            render={() => (
              <MacroList
                resetSearch={resetSearch}
                searchResults={searchResults}
                showSearchResults={showSearchResults}
                searchCount={searchCount}
                setUserUploads={setUserUploads}
                userUploads={userUploads}
                user={user}
                setUser={setUser}
              />
            )}
          /> */}
          <Route
            exact
            path="/macroDetails/:macroId"
            render={() => (
              <MacroDetails
                showDefaultSearch={showDefaultSearch}
                disqusData={disqusData}
                showSearchResults={showSearchResults}
                setUserUploads={setUserUploads}
                userUploads={userUploads}
                gameSearchResults={searchResults}
                setUser={setUser}
              />
            )}
          />
          <Route
            path="/myUploads"
            render={() => (
              <MyUploads
                showDefaultSearch={showDefaultSearch}
                showSearchResults={showSearchResults}
                setUserUploads={setUserUploads}
              />
            )}
          />
          <Route
            path="/uploadMacro"
            render={() => <UploadMacro showHeader={showHeader} />}
          />
          <Route
            path="/allGames"
            render={() =>
              <AllGames
                showSearchResults={showSearchResults}
                showSortOnAllGames={showSortOnAllGames}/>}
          />
          <Redirect from="/" to="/macroList"/>
        </HashRouter>
      </div>
    </div>
  );
};

export default App;
