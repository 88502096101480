import React from "react";
import {FormattedMessage} from "react-intl";
import CommonButton from "../CommonButton/CommonButton";

const CommonSection = ({img, heading, subHeading, btnText, onClick, searchField, searchResults}) => {
  return (
    <div className="commonSection column flex perfectCenter">
      <img alt="macros" src={img} className="margin-bottom-20"></img>
      <p className="bold font24 margin-bottom-10 darkBluetext textCenter">
        {searchResults && <FormattedMessage id={heading} values={{ searchField: searchField }} />}
        {!searchResults && <FormattedMessage id={heading} />}
      </p>
      <p className="font16 margin-bottom-25 textCenter blueText">
        <FormattedMessage id={subHeading} />
      </p>
      <CommonButton
        text={btnText}
        onClick={onClick}
        className="uploadNowBtn"
      />
    </div>
  );
};

export default CommonSection;
