import React from "react";
import { FormattedMessage } from "react-intl";
import { sendEventStats } from "../../../../actions/eventActions";
import { useSelector } from "react-redux";

const QuickHelp = () => {
    const email = useSelector(state => state.user.user_email);
    const goToLink = () => {
        if (window.GmApi.isAvailable()) {
            window.GmApi.gmShowWebPage(
                "",
                "https://support.bluestacks.com/hc/articles/360048200932"
            );
        } else {
            window.open("https://support.bluestacks.com/hc/articles/360048200932");
        }
        sendEventStats(null, "macroCommunity_touchPoint_articleClicked", email)
    }
    return (
        <div className="quickHelp font14">
            <p className="darkBluetext header"><FormattedMessage id="quickHelp" /></p>
            <ul className="listItems linkColor pointer">
                <li onClick={goToLink}><FormattedMessage id="seeMacroTouch" /></li>
            </ul>
        </div>
    )
};

export default QuickHelp;