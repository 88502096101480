import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import messages_fr from "../json/i18n/fr-FR.json";
import messages_en from "../json/i18n/en-US.json";
import messages_ar from "../json/i18n/ar-EG.json";
import messages_de from "../json/i18n/de-DE.json";
import messages_enk from "../json/i18n/en-EN.json";
import messages_es from "../json/i18n/es-ES.json";
import messages_it from "../json/i18n/it-IT.json";
import messages_ja from "../json/i18n/ja-JP.json";
import messages_ko from "../json/i18n/ko-KR.json";
import messages_pl from "../json/i18n/pl-PL.json";
import messages_pt from "../json/i18n/pt-BR.json";
import messages_ru from "../json/i18n/ru-RU.json";
import messages_th from "../json/i18n/th-TH.json";
import messages_tw from "../json/i18n/zh-TW.json";
import messages_vi from "../json/i18n/vi-VN.json";
import messages_tr from "../json/i18n/tr-TR.json";

const messages = {
  "fr-FR": messages_fr,
  "en-US": messages_en,
  "ar-EG": messages_ar, 
  "de-DE": messages_de, 
  "en-EN": messages_enk, 
  "es-ES": messages_es, 
  "it-IT": messages_it, 
  "ja-JP": messages_ja, 
  "ko-KR": messages_ko, 
  "pl-PL": messages_pl, 
  "pt-BR": messages_pt, 
  "ru-RU": messages_ru, 
  "th-TH": messages_th, 
  "zh-TW": messages_tw, 
  "vi-VN": messages_vi,
  "tr-TR": messages_tr 
};

class LocaleProvider extends Component {
  constructor() {
    super();
    this.state = {
      language: "en-US"
    };
  }

  componentDidMount() {
    let language = window.GmApi.gmGetLocale();

    if (language == null || language === "") {
      language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    }
    if (language == null || language === "") {
      language = "en-US";
    }

    if (!messages[language]) {
      language = "en-US";
    }

    this.setState({ language: language });
  }

  render = () => {
    const { language } = this.state;
    const message = language === "en-US" ? messages_en : {...messages_en, ...messages[language]};
    return (
      <IntlProvider locale={language} messages={message}>
        {this.props.children}
      </IntlProvider>
    );
  };
}

export default LocaleProvider;
