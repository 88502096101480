import React, { useEffect, useState,useRef } from "react";
import CommonSection from "../common/CommonSection/CommonSection";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MacroListItem from "../MacroList/MacroListItem";
import Loader from "../common/Loader/Loader";
import { FormattedMessage } from "react-intl";
import GoogleSignIn from "../common/GoogleSignIn/GoogleSignIn";
import SidePanel from "../common/SidePanel/SidePanel";
import { setSearchQuery } from "../../actions/commonActions";
import { setSortedMacros, getMacros, setEndOfMacros, resetFilteredMacrosData } from "../../actions";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";


const MyUploads = ({ showDefaultSearch, showSearchResults, setUserUploads,intl }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_nickname = useSelector(state => state.user.user_nickname);
  const email = useSelector(state => state.user.user_email);
  const [googleSignIn, showSignInModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const userName = urlParams.get("user");
  const cursor = useSelector(state => state.apps.cursor);
  const loader = useSelector(state => state.apps.loader);
  const filteredMacros = useSelector(state => state.apps.filteredMacros);
  const selectedSortingValue = useSelector(state => state.apps.sort_by); 

  const myUploads = filteredMacros;
  const nickname = userName || user_nickname;
  const [isSuccessMessage, setMesssage] = useState(location.state ? location.state.showSuccessMessage : '');
  setTimeout(function(){ setMesssage(false) }, 3000);
  useEffect(() => {
    dispatch(resetFilteredMacrosData());
    showDefaultSearch(false);
    dispatch(setSearchQuery(""));
    return ()=> {
      showDefaultSearch(true);
    };
  }, []);

  const [user, setUser] = useState(urlParams.get("email"));
  const macroListContainer = useRef(null);
  const sidePanel = useRef(null);
  const [top, resetOffsetTop] = useState(0);
  const [sortValue, updateSort] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const end_of_macros = useSelector(state => state.apps.end_of_macros);


  const showMoreMacros = () => {
    if(myUploads.length > 0) {
    return (
        myUploads.map(macro => (
          <MacroListItem
            key={macro.content_id}
            macro={macro}
            showSearchResults={showSearchResults}
            link={"backToUploads"}
            nickname={nickname}
            setUserUploads={setUserUploads}
            user={user}
            setUser={setUser}
            uploaderEmail={email}
            updateSort={updateSort}
            intl={intl}
          />
        ))
      );
    }
  };
  const loadMoreMacros = () => {
    if (end_of_macros) {
        setHasMoreItems(false);
    } else {
      dispatch(getMacros(email, null, null, selectedSortingValue, cursor, loader, false, email));
    }
  }

  let offsetTop;

  function updateOffSetTop(){
    offsetTop = macroListContainer && macroListContainer.current &&  macroListContainer.current.offsetTop;
    resetOffsetTop(offsetTop);
  }
  
  useEffect(() => {
    updateOffSetTop();
    window.addEventListener("resize", updateOffSetTop);
    return () => window.removeEventListener("resize", updateOffSetTop);
  }, [ filteredMacros ]);
  const onClick = () => {
    if (email) {
      history.push("/uploadMacro");
    } else {
      if (!window.GmApi.isAvailable()) {
        setRedirectLink("/uploadMacro");
        showSignInModal(true);
      }
    }
  };
  
  useEffect(() => {
    if(myUploads){
      setHasMoreItems(true);
      dispatch(setEndOfMacros(false));
      dispatch(getMacros(email, null, null, selectedSortingValue, null, true, true, email, true));
      dispatch(setSortedMacros([], "UserUploadMacros"));
    }
  }, [ selectedSortingValue,sortValue ]);

  if (loader) return <Loader />;

  if (!myUploads) return null;

  return (
    <>
      {isSuccessMessage && (
        <div className="macroSuccess">
          <span className="font-16 msgSuccess"><FormattedMessage id="messageAddedSuccessfully" /></span>
          <span className="closeMsg" onClick={() => setMesssage(false) }> X </span>
        </div>
      )}
      <div className="flex start" ref={macroListContainer}>
        <div className="sidePanel" ref={sidePanel} style={{top:top}}>
            <SidePanel
              showSearchResults={showSearchResults}
            />
          </div>
            {myUploads.length > 0 && (
              <ul className="macroList macroListItems">
                <InfiniteScroll
                  loadMore={loadMoreMacros}
                  hasMore={hasMoreItems}
                  loader= {loader ? <Loader /> : null }
                  useWindow={true}
                  threshold={400}
                >
                  {showMoreMacros()}
                </InfiniteScroll>
                </ul>
              )}
        {!myUploads.length && (
          <div className="myUploadsSection">
            <CommonSection
              heading="giveBack"
              subHeading="haveMacros"
              img={`${process.env.PUBLIC_URL}/images/macro_icon.svg`}
              btnText="uploadNow"
              onClick={onClick}
            />
          </div>
        )}
      </div>
      {googleSignIn && (
        <GoogleSignIn showSignInModal={showSignInModal} link={redirectLink} />
      )}
    </>
  );
};

export default injectIntl(MyUploads);
