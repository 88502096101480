import React from "react";

const InputField = ({ type, className, onChange, required, value, placeholder, minLength, maxLength }) => {
  return type === "text" ? (
    <input type={type} data-title="please fill out this field" className={className} onChange={onChange} required={required} value={value} placeholder={placeholder} minLength={minLength} maxLength={maxLength} />
  ) : (
    <textarea className={className} data-title="please fill out this field" onChange={onChange} required={required} value={value} placeholder={placeholder} />
  );
};

export default InputField;
