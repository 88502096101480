import React from "react";
import InputContainer from "../common/InputContainer/InputContainer";
import { FormattedMessage } from "react-intl";
import CommonButton from "../common/CommonButton/CommonButton";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { FormHelperText } from "@material-ui/core";

const UploadFileSection = ({
  title,
  titleError,
  setTitle,
  titleLengthError,
  setTitleLengthError,
  descriptionLengthError,
  setDescriptionLengthError,
  setTitleError,
  description,
  setDescriptionError,
  descriptionError,
  setDescription,
  setTags,
  setSearchTagsError,
  searchTagsError,
  tags,
  intl,
  handleSubscribeCheckbox,
  subscribed,
  errorMsg,
}) => {
  const nickName = useSelector((state) => state.user.user_nickname);

  const changeTitle = (event) => {
    setTitle(event.target.value);
    if (event.target.value) setTitleError(false);
    if (event.target.value && event.target.value.length < 50)
      setTitleLengthError(false);
  };
  const changeDescription = (event) => {
    setDescription(event.target.value);
    if (event.target.value) setDescriptionError(false);
    if (event.target.value && event.target.value.length > 30)
      setDescriptionLengthError(false);
  };
  const changeSetSearchTag = (event) => {
    setTags(event.target.value);
    if (event.target.value) setSearchTagsError(false);
  };

  return (
    <>
      <InputContainer
        type="text"
        className={`inputField  ${
          titleError || titleLengthError ? "errorList" : "margin-bottom-20"
        }`}
        onChange={changeTitle}
        label="title"
        value={title}
        placeholder={intl.formatMessage({ id: "whatDoesYour" })}
      />
      {titleError && (
        <p className="margin-bottom-20">
          <FormHelperText className="warningText">
            {intl.formatMessage({ id: "pleaseFillThisField" })}
          </FormHelperText>
        </p>
      )}
      {titleLengthError && (
        <p className="margin-bottom-20">
          <FormHelperText className="warningText">
            {intl.formatMessage({ id: "titleCannotExceed" })}
          </FormHelperText>
        </p>
      )}
      <InputContainer
        type="textarea"
        className={`inputField textarea ${
          descriptionError || descriptionLengthError
            ? "errorList"
            : "margin-bottom-20"
        }`}
        onChange={changeDescription}
        label="description"
        value={description}
        placeholder={intl.formatMessage({ id: "describeTheScenario" })}
      />
      {descriptionError && (
        <p className="margin-bottom-20">
          <FormHelperText className="warningText">
            {intl.formatMessage({ id: "pleaseFillThisField" })}
          </FormHelperText>
        </p>
      )}
      {descriptionLengthError && (
        <p className="margin-bottom-20">
          <FormHelperText className="warningText">
            {intl.formatMessage({ id: "descriptionString" })}
          </FormHelperText>
        </p>
      )}
      <InputContainer
        type="text"
        className={`inputField ${
          searchTagsError ? "errorList" : "margin-bottom-20 "
        }`}
        onChange={changeSetSearchTag}
        label="searchTags"
        value={tags}
        placeholder={intl.formatMessage({ id: "tryEntering" })}
      />
      {searchTagsError && (
        <p className="margin-bottom-20">
          <FormHelperText className="warningText">
            {intl.formatMessage({ id: "pleaseFillThisField" })}
          </FormHelperText>
        </p>
      )}
      <div className="checkboxContainer flex vrtlCenter margin-bottom-25 font10 lightGreyColor">
        <input
          type="checkbox"
          className="subscribeCheckbox margin-right-10"
          checked={subscribed}
          onChange={handleSubscribeCheckbox}
        />
        <div className="fakeCheckBox" />
        <FormattedMessage id="subscribeToEmails" />
      </div>
      {errorMsg && (
        <div className="warningText margin-bottom-20">
          <FormattedMessage id={errorMsg} />
        </div>
      )}
      <div className="flex spaceBetween vrtlCenter">
        <CommonButton
          className="submitMacroBtn primaryBtn font16 bold"
          text="submitMacro"
        />
      </div>
    </>
  );
};

export default injectIntl(UploadFileSection);
