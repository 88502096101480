import React from "react";
// import AdsSection from "./AdsSection/AdsSection";
import PopularGames from "./PopularGames/PopularGames";
import QuickHelp from "./QuickHelp/QuickHelp";

const SidePanel = ({
    showSearchResults
}) => {
    return (
        <>
            <PopularGames
                showSearchResults={showSearchResults}
            />
            <QuickHelp />
            {/* <AdsSection/> */}
        </>
           
    );
}

export default SidePanel;