import { createSelector } from "reselect";

const macroListSelector = state => state.apps.macros;

export const macrosSelector = createSelector(
  macroListSelector,
  macro => {
    return macro;
  }
);
