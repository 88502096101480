const constants = {
  MACROS_DATA_SUCCESS: "MACROS_DATA_SUCCESS",
  SET_QUERY: "SET_QUERY",
  SET_SELECTED_MACRO: "SET_SELECTED_MACRO",
  SHOW_FILTERED_MACROS: "SHOW_FILTERED_MACROS",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  MACROS_DELETE_SUCCESS: "MACROS_DELETE_SUCCESS",
  MACRO_LIKE_SUCCESS: "MACRO_LIKE_SUCCESS",
  MACRO_DOWNLOAD_SUCCESS: "MACRO_DOWNLOAD_SUCCESS",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
  EDIT_NAME_SUCCESS: "EDIT_NAME_SUCCESS",
  SSO_SUCCESS: "SSO_SUCCESS",
  MACROS_UPLOAD_SUCCESS: "MACROS_UPLOAD_SUCCESS",
  MACROS_EDIT_SUCCESS: "MACROS_EDIT_SUCCESS",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SET_INSTANCE: "SET_INSTANCE",
  enggUrl: "https://macros-engg.bluestacks.com",
  prodUrl: "https://macros.bluestacks.com",
  prodHost: "https://cloud.bluestacks.com",
  enggHost: "https://macro-community-dot-bs3-appcenter-engg.appspot.com",
  SHOW_USER_FILTERED_MACROS: "SHOW_USER_FILTERED_MACROS",
  GET_GAMES_SUCCESS: "GET_GAMES_SUCCESS",
  MACROS_SEARCH_DATA_SUCCESS: "MACROS_SEARCH_DATA_SUCCESS",
  SEARCHED_STRING: "SEARCHED_STRING",
  SET_CATEGORY: "SET_CATEGORY",
  SORT_STATUS: "SORT_STATUS",
  SORT_STATUS_ALL_GAMES:"SORT_STATUS_ALL_GAMES",
  SET_SORTED_MACROS: "SET_SORTED_MACROS",
  GAME_NAME:"GAME_NAME",
  SEARCH_RECOMMENDATION_SUCCESS: "SEARCH_RECOMMENDATION_SUCCESS",
  ALL_GAMES_SUCCESS: "ALL_GAMES_SUCCESS",
  RESET_MACROS: "RESET_MACROS",
  END_OF_MACROS: "END_OF_MACROS",
  RESET_FILTERED_MACROS: "RESET_FILTERED_MACROS",
  MACRO_DETAILS_SUCCESS: "MACRO_DETAILS_SUCCESS",
  MACRO_DETAILS_FETCH: "MACRO_DETAILS_FETCH",
  GSI_INIT: "GSI_INIT",
  USER_INFO: "USER_INFO",
};

export default constants;
