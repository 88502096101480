import history from "../history";
import consts from "../consts";
import axios from "axios";

const urlParams = new URLSearchParams(window.location.search);
const mod = urlParams.get("module") ? "https://macro-community-dot-bs3-appcenter-engg.appspot.com" : null;
const host = window.GmApi.gmDevUrl() || mod || "https://cloud.bluestacks.com";

export const goToHome = () => {
  history.push("/");
};

export const setSearchQuery = query => {
  return dispatch => {
    return dispatch({
      type: consts.SET_QUERY,
      payload: query
    });
  };
};

export const searchResults = query => {
  return {
      type: consts.SET_SEARCH_QUERY,
      payload: query
  }
};

export const downloadMacro = (params) => {
  const macroReqObj = {
     "download_link": params.download_link,
     "macro_name": params.macroName,
     "nickname": params.nickName, 
     "author_url": `${window.location.origin}/#/?email=${params.email}`,
     "macro_url": `${window.location.origin}/#/macroDetails/${params.id}`,
     "macro_id": params.id
    }
  window.GmApi.downloadMacro(macroReqObj);
}

export const likeDownloadMacro = (id, userAction, email, macros) => {
  const url = `${host}/communitycontent/user_activity`;
  let bodyFormData = new FormData();
  bodyFormData.set("user_email", email);
  bodyFormData.set("content_id", id);
  bodyFormData.set("user_action", userAction);
  return async dispatch => {
    try {
      await axios({
        method: "post",
        url: url,
        data: bodyFormData
      });
      if (userAction === "like") {
        return dispatch({
          type: consts.MACRO_LIKE_SUCCESS,
          payload: {id:id, macros:macros}
        });
      }
      return dispatch({
        type: consts.MACRO_DOWNLOAD_SUCCESS,
        payload: {id, macros}
      });
    } catch (error) {
      return error;
    }
  };
}

export const sortMacros=(macroList,selectedSortingOption)=>{
  
  if(macroList && macroList.length > 0 && selectedSortingOption)
    {
      macroList = [...macroList].sort((a, b) => (a.content_name > b.content_name) ? 1 : -1);
        switch(selectedSortingOption){
            case "comment_count":
              return [...macroList].sort((a, b) => (a.comment_count < b.comment_count) ? 1 : -1)
            case "likes_count":
              return [...macroList].sort((a, b) => (a.likes_count < b.likes_count) ? 1 : -1)
            case "modified_at":
              return [...macroList].sort((a,b)=> (new Date(a.modified_at)).getTime() < (new Date(b.modified_at)).getTime() ? 1 : -1);
            case "recommended":
              return [...macroList].sort((a, b) => (a.position > b.position) ? 1 : -1);
            default:
              return [...macroList].sort((a, b) => (a.download_count < b.download_count) ? 1 : -1)
          }
    }
    return macroList;
}

export const sortMacroAllGames=(macroList,selectedSortingOption)=>{

  if(macroList && macroList.length > 0 && selectedSortingOption)
  {
    macroList = [...macroList].sort((a, b) => (a.game_name > b.game_name) ? 1 : -1);
    switch(selectedSortingOption){
      case "modified_at":
          return [...macroList].sort((a,b)=> (new Date(a.modified_at)).getTime() < (new Date(b.modified_at)).getTime() ? 1 : -1);
      case "alphabetical":
          return [...macroList].sort((a, b) => (a.game_name > b.game_name) ? 1 : -1)
      default:
          return [...macroList].sort((a, b) => (a.count < b.count) ? 1 : -1)
    }
  }   
  return macroList;        
}

export const setGameName = query => {
  return dispatch => {
    return dispatch({
      type: consts.GAME_NAME,
      payload: query
    });
  };
};

export const setUserInfo = (userInfo) => {
  return {
    type: consts.USER_INFO,
    payload: userInfo
  }
}