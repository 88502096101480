import { combineReducers } from "redux";
import appReducer from "./appReducer";
import userReducer from "./userReducer";

const reducer = combineReducers({
  apps: appReducer,
  user: userReducer
});

export default reducer;
