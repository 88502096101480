export const macroDataTransformer = response => {
  const {
    data: {result, cursor, total_macros}
  } = response;
  return { macros: result, cursor: cursor, total_macros: total_macros };
};

export const filteredMacroDataTransformer = response => {
  const {
    data: {result, cursor, total_macros}
  } = response;
  return { filteredMacros: result, cursor: cursor, total_macros: total_macros };
};

export const userDataTransformer = response => {
  const {message} = response;
  return {user_email: message.email_data, user_nickname: message.nickname_data, userId: message.token_data, avatar: message.avatar_url_data}
}
