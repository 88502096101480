import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useHistory, Link } from "react-router-dom";
import ReactGA from 'react-ga';

import { setSearchQuery,searchResults } from "../../../../actions/commonActions";
import { getMacros } from "../../../../actions/index";
import { FormattedMessage } from "react-intl";
import { sendEventStats } from "../../../../actions/eventActions";

const PopularGames =({
    showSearchResults
}) =>{
    const imgLoadUrl = "https://cloud.bluestacks.com/app/icon";
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const email = useSelector(state => state.user.user_email);
    const category = useSelector(state => state.apps.category);
    const sortBy = useSelector(state => state.apps.sort_by);
    const drillDown = (query, pkg, type) => {
        if (!type) {
            dispatch(getMacros(email, query, pkg, sortBy, null, true, true));
            dispatch(setSearchQuery(query));
            dispatch(searchResults(query));
            showSearchResults();
        }
        if (type === "recommendation") {
            sendEventStats({
                app_pkg:pkg,
                game_name:query
            }, "macroPopularGames" , email);
            history.push({
            pathname: `/macroList/${query}`,
            search: `?searchTerm=${query}${pkg ? `&app_pkg=${pkg}` : "" }`
          });
          ReactGA.event({
            category: category,
            action: "Search Recommendation Link Click",
            label: query
          })
        }
      },
      limitOfGamesToShow=6,
      gamesList = useSelector(state => state.apps.all_games),
      searchRecommendations = useSelector(state => state.apps.search_recommendation);
      if (!searchRecommendations) return null;
 
    let showViewAllGames = gamesList && gamesList.length > 0 ? true : false,
        truncatedRecommendationList= searchRecommendations.slice(0, limitOfGamesToShow);


    return(
        <>
            {searchRecommendations && (
                <div className="popularGamesContainer">
                    <p className="item header"><FormattedMessage id="popularGames"/></p>
                    <div className="body">
                        {truncatedRecommendationList.map(rec => {
                        return (
                            <div className="itemGroup item" 
                                key={rec.app_pkg}
                                onClick={() => drillDown(rec.name, rec.app_pkg, 'recommendation')}>
                                <div className="itemGroup">
                                    <img className="gameImg" alt="" 
                                        src={`${imgLoadUrl}?pkg=${rec.app_pkg}`}/>
                                    <p className="title">{rec.name}</p>
                                </div>                       
                                <p className="commentsCount">{rec.count}</p>
                            </div>
                        );
                        })}
                    </div>
                    {showViewAllGames && !(location.pathname.toLowerCase().includes("/allgames")) && (
                        <div className="footer item"
                                onClick={() => drillDown("","", 'viewAllGames')}>
                            <Link to={"/allGames"} className="viewAllGamesLink">
                                    <FormattedMessage id="view_all_games"/>
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default PopularGames;