import React,{useRef,useEffect,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import AllGamesItem from "./AllGamesItem";
import SidePanel from "../common/SidePanel/SidePanel";
import {setSearchQuery,sortMacroAllGames} from "../../actions/commonActions";

const AllGames = ({
    showSearchResults,
    showSortOnAllGames
}) => {
    const dispatch = useDispatch();
    const gamesList = useSelector(state => state.apps.all_games);
    const allGamesContainer = useRef(null);
    const sidePanel = useRef(null);
    const selectedSortingValue = useSelector(state => state.apps.sort_by_all_games);
    const sortGameslist = selectedSortingValue && gamesList && sortMacroAllGames(gamesList,selectedSortingValue);
    const [top, resetOffsetTop] = useState(0);
    let offSetTop;

    function updateOffSetTop(){
        offSetTop = allGamesContainer && allGamesContainer.current &&  allGamesContainer.current.offsetTop;
        resetOffsetTop(offSetTop);        
    }
        
    useEffect(() => {
        window.addEventListener("resize", updateOffSetTop)
        showSortOnAllGames(true);
        dispatch(setSearchQuery(""));
        window.scrollTo(0,0);
        return() => {
            showSortOnAllGames(false);
            window.removeEventListener("resize", updateOffSetTop);
        };
    }, []);

  useEffect(() => {
    updateOffSetTop();
    window.addEventListener("resize", updateOffSetTop);
    return () => window.removeEventListener("resize", updateOffSetTop);
  }, [gamesList]);

    if (!sortGameslist) {
        return null;
    }

    return (
        <>
            <div className="flex start" ref={allGamesContainer}>
                <div className="sidePanel" ref={sidePanel} style={{top:top}}>
                    <SidePanel
                        showSearchResults={showSearchResults}
                />
                </div>
                <div className="gameListContainer">
                    {sortGameslist.length > 0 && (
                        sortGameslist.map((gameObj,index) => (
                        <AllGamesItem
                            indexKey={index}
                            macroCount ={gameObj.count}
                            gameName={gameObj.game_name}
                            appPackage={gameObj.app_pkg}/>
                    )))}
                </div>
            </div>
        </>
    )
}

export default AllGames;